import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import {
  AgentFieldsFragment,
  CompanyAgentFieldsFragment,
  MessageHistoryFieldsFragment,
  StepHistoryFieldsFragment,
} from '../../graphql'

export interface OneChatProps {
  isQuerying: boolean
  query: string
  chatId: string
  threadUuid: string
  messages: MessageHistoryFieldsFragment[]
  steps: StepHistoryFieldsFragment[]
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
  agentUuid: string
}

export interface ChatState {
  [key: string]: OneChatProps
}

const initialChatProps: Omit<OneChatProps, 'chatId'> = {
  isQuerying: false,
  query: '',
  messages: [],
  steps: [],
  threadUuid: '',
  agent: null,
  agentUuid: '',
}

const initialState: ChatState = {}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    createChat: {
      prepare: () => {
        const chatId = uuidv4()
        return { payload: { chatId } }
      },
      reducer: (state, action: PayloadAction<{ chatId: string }>) => {
        const { chatId } = action.payload
        state[chatId] = { ...initialChatProps, chatId }
      },
    },
    removeChat: (state, action: PayloadAction<{ chatId: string }>) => {
      const { chatId } = action.payload
      delete state[chatId]
    },
    setIsQuerying: (state, action: PayloadAction<{ chatId: string; isQuerying: boolean }>) => {
      const { chatId, isQuerying } = action.payload
      if (state[chatId]) {
        state[chatId].isQuerying = isQuerying
      }
    },
    setQuery: (state, action: PayloadAction<{ chatId: string; query: string }>) => {
      const { chatId, query } = action.payload
      if (state[chatId]) {
        state[chatId].query = query
      }
    },
    addMessage: (
      state,
      action: PayloadAction<{ chatId: string; message: MessageHistoryFieldsFragment }>
    ) => {
      const { chatId, message } = action.payload
      if (state[chatId]) {
        state[chatId].messages.push(message)
      }
    },
    addStep: (
      state,
      action: PayloadAction<{ chatId: string; step: StepHistoryFieldsFragment }>
    ) => {
      const { chatId, step } = action.payload
      if (state[chatId]) {
        state[chatId].steps.push(step)
      }
    },
    setMessages: (
      state,
      action: PayloadAction<{ chatId: string; messages: MessageHistoryFieldsFragment[] }>
    ) => {
      const { chatId, messages } = action.payload
      if (state[chatId]) {
        state[chatId].messages = messages
      }
    },
    setSteps: (
      state,
      action: PayloadAction<{ chatId: string; steps: StepHistoryFieldsFragment[] }>
    ) => {
      const { chatId, steps } = action.payload
      if (state[chatId]) {
        state[chatId].steps = steps
      }
    },
    clearSteps: (state, action: PayloadAction<{ chatId: string }>) => {
      const { chatId } = action.payload
      if (state[chatId]) {
        state[chatId].steps = []
      }
    },
    clearMessages: (state, action: PayloadAction<{ chatId: string }>) => {
      const { chatId } = action.payload
      if (state[chatId]) {
        state[chatId].messages = []
      }
    },
    setThreadUuid: (state, action: PayloadAction<{ chatId: string; threadUuid: string }>) => {
      const { chatId, threadUuid } = action.payload
      if (state[chatId]) {
        state[chatId].threadUuid = threadUuid
      }
    },
    setAgent: (
      state,
      action: PayloadAction<{
        chatId: string
        agent: AgentFieldsFragment | CompanyAgentFieldsFragment
      }>
    ) => {
      const { chatId, agent } = action.payload
      if (state[chatId]) {
        state[chatId].agent = agent
      }
    },
    setAgentUuid: (state, action: PayloadAction<{ chatId: string; agentUuid: string }>) => {
      const { chatId, agentUuid } = action.payload
      if (state[chatId]) {
        state[chatId].agentUuid = agentUuid
      }
    },
    resetChat: (state, action: PayloadAction<{ chatId: string }>) => {
      const { chatId } = action.payload
      if (state[chatId]) {
        state[chatId] = { ...initialChatProps, chatId }
      }
    },
  },
})

export const { actions: chatActions } = chatSlice

export default chatSlice.reducer
