import User from './User'
import SkillSet from './SkillSet'

interface Meta {
  defaultSettings: Record<string, unknown>
  domains: string[]
  steps: Record<string, any>[]
  stepCount: number
  rrweb?: boolean
  startURL?: string
  thumbnailPublicId?: string
  gifUrl?: string
  totalTime: string
  chunkCount: number
  duration: number
}

export default interface Walkthrough {
  id: number
  uuid: string
  name: string
  description: string
  meta: Meta
  steps: Record<string, any>[]
  createdBy: User
  skillSet: SkillSet
  canEdit: boolean
  canMove: boolean
  canEditSharing: boolean
  canDelete: boolean
  isOwner: boolean
  isInMyLibrary: boolean
  isInMyOrganizationLibrary: boolean
  completed?: boolean
  sharedAccess: string
  createdAt: string
  library: string
  rrwebEventsUrl: string
  audioUrl: string
  liveLearning: boolean
  labs: boolean
  embedEnabled: boolean
  audioEnabled: boolean
  hasAudio: boolean

  viewCount: number
  interactionCount: number

  synthesis: string
  synthesisStatus: string
  summary: string
  lastSynthesisAt: string
  updatedAt: string

  __typename: string
}

export const StepTypes = {
  cssSelector: 'css_selector',
  click: 'click',
  input: 'input',
  dropdownSelect: 'dropdown_select',
  goto: 'goto',
  copyPaste: 'copy_paste',
  highlighting: 'highlighting',
  textOnly: 'text_only',
  inputText: 'input_text',
  media: 'media',
  video: 'video',
  ensureSelector: 'ensure_selector',
  section: 'section',
}
