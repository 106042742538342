import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Bar } from '../../components/Containers'
import Timer from '../../components/Timer'
import { DeleteButton, PlayPauseButton } from '../../components/Buttons'
import Button from '../../components/Buttons'
import Progress from '../../components/Progress'
import Modal from '../../components/Modal'
import Walkthrough from '../../../../../../App/models/Walkthrough'
import {
  GET_WALKTHROUGH_QUERY,
  UPLOAD_AUDIO,
  WalkthroughData,
} from '../../../../../../App/graphql/queries/walkthrough'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, PlayerActions } from '../../../../../../AI/components/Player/PlayerState'

type ReplayProps = {
  close: () => void
  file: any
  walkthrough: Walkthrough
  wave: number[]
  audioDuration?: number
}

const Replay: React.FC<ReplayProps> = ({ close, file, walkthrough, wave, audioDuration }) => {
  const dispatch = useDispatch()
  const {
    duration,
    position,
    playerControls: { isPlaying },
  } = useSelector((state: RootState) => state.player)
  const [uploadAudio, { loading }] = useMutation(UPLOAD_AUDIO, {
    update(cache, { data: { uploadAudio } }) {
      const uuid = uploadAudio.uuid

      const data = cache.readQuery<WalkthroughData>({
        query: GET_WALKTHROUGH_QUERY,
        variables: { uuid },
      })

      const walkthrough = {
        ...data.walkthrough,
        hasAudio: true,
      }

      if (data.walkthrough.audioUrl) {
        walkthrough.audioUrl = walkthrough.audioUrl + '?timestamp=' + Date.now()
      } else {
        walkthrough.audioUrl = uploadAudio.audioUrl
      }

      cache.writeQuery({
        query: GET_WALKTHROUGH_QUERY,
        variables: { uuid },
        data: {
          walkthrough,
        },
      })
    },
  })
  const [modal, setModal] = useState<boolean>(false)

  const handleDelete = () => {
    dispatch(PlayerActions.pause())
    setModal(true)
  }

  const handleSave = () => {
    dispatch(PlayerActions.restart())
    uploadAudio({
      variables: {
        walkthroughId: walkthrough.id,
        file,
      },
    }).then(() => {
      close()
    })
  }

  return (
    <React.Fragment>
      <Modal display={modal}>
        <div className="text-base text-gray-600 mb-6">
          Are you sure you want to delete the audio recording?
        </div>
        <div className="flex gap-x-2">
          <Button type="neutral" className="flex-1" onClick={() => setModal(false)}>
            Cancel
          </Button>
          <Button type="primary" className="flex-1" onClick={close}>
            Delete
          </Button>
        </div>
      </Modal>
      <Bar>
        <div
          className={`w-full flex items-center ${
            modal || loading ? 'opacity-50 pointer-events-none' : ''
          }`}
        >
          <div className="mr-4">
            <PlayPauseButton
              playing={isPlaying}
              toggle={() => {
                dispatch(PlayerActions.togglePlayPause())
              }}
            />
          </div>
          <div className="flex flex-1 items-center mr-4">
            <div className="flex-1 mr-4">
              <Progress
                replay={true}
                wave={wave}
                audioDuration={audioDuration}
                duration={duration}
                position={position}
                playing={isPlaying}
              />
            </div>
            <Timer duration={duration} position={position} />
          </div>
          <div className="flex items-center gap-x-2">
            <DeleteButton handleDelete={handleDelete} />
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </Bar>
    </React.Fragment>
  )
}

export default Replay
