import React from 'react'
import MessageStream from '../Chat/MessageStream'
import { useChatSelector } from '../../store'
import Message from '../Chat/Message'
import RunStep from '../../pages/app/OneAgentChat/RunStep'
import {
  AgentFieldsFragment,
  CompanyAgentFieldsFragment,
  MessageHistoryFieldsFragment,
  StepHistoryFieldsFragment,
} from 'app/javascript/components/graphql'

interface BaseMessageThreadProps {
  messages: MessageHistoryFieldsFragment[]
  steps: StepHistoryFieldsFragment[]
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
  showName: boolean
  showVoting: boolean
}

export const BaseMessageThread = ({
  messages,
  steps,
  agent,
  showName,
  showVoting,
}: BaseMessageThreadProps) => {
  const messagesWithType = messages.map((message) => ({
    ...message,
    type: 'message',
  }))
  const stepsWithType = steps.map((step) => ({
    ...step,
    type: 'step',
  }))

  const interleavedItems: any[] = []

  let stepIndex = 0
  for (let i = 0; i < messagesWithType.length; i++) {
    const currentMessage = messagesWithType[i]
    const nextMessage = messagesWithType[i + 1]

    interleavedItems.push(currentMessage)

    while (
      stepIndex < stepsWithType.length &&
      (!nextMessage || stepsWithType[stepIndex].createdAtMilli < nextMessage.createdAtMilli)
    ) {
      if (stepsWithType[stepIndex].createdAtMilli > currentMessage.createdAtMilli) {
        interleavedItems.push(stepsWithType[stepIndex])
      }
      stepIndex++
    }
  }

  // Add any remaining steps
  while (stepIndex < stepsWithType.length) {
    interleavedItems.push(stepsWithType[stepIndex])
    stepIndex++
  }

  return (
    <div className="flex flex-col space-y-6">
      {interleavedItems.map((item, index) => {
        const previousItem = index > 0 ? interleavedItems[index - 1] : null
        const showNextName = !previousItem || previousItem.actor !== item.actor
        if (item.type === 'message') {
          return (
            <Message
              agent={agent}
              message={item as MessageHistoryFieldsFragment}
              key={index}
              showName={showName && showNextName}
              showVoting={showVoting}
            />
          )
        } else if (item.type === 'step') {
          return (
            <RunStep
              agent={agent}
              step={item as StepHistoryFieldsFragment}
              key={index}
              showName={showName && showNextName}
            />
          )
        }
        return null
      })}
    </div>
  )
}

interface MessageThreadProps {
  chatId: string
  showName: boolean
}

const MessageThread = ({ chatId, showName }: MessageThreadProps) => {
  const chatState = useChatSelector(chatId)
  if (!chatState) {
    return <></>
  }
  const { isQuerying, messages, steps, agent } = chatState

  return (
    <div className="flex flex-col space-y-6">
      <BaseMessageThread
        messages={messages}
        steps={steps}
        agent={agent}
        showName={showName}
        showVoting={true}
      />
      {isQuerying && <MessageStream chatId={chatId} agent={agent} showName={showName} />}
    </div>
  )
}

export default MessageThread
