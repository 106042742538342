import React, { useCallback, useEffect, useRef } from 'react'
import PromptInput from '../../../components/Chat/PromptInput'
import { chatActions } from '../../../slices/chatSlice'
import { useDispatch } from 'react-redux'
import { useChatSelector } from '../../../store'
import MessageThread from '../../../components/SidebarChat/MessageThread'

const Chat = ({ chatId }: { chatId: string }) => {
  const dispatch = useDispatch()

  const divRef = useRef<HTMLDivElement>(null)
  const chat = useChatSelector(chatId)
  const { isQuerying, messages, steps } = chat || {}

  const scrollToBottom = useCallback(() => {
    const element = divRef.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }, [divRef.current])

  useEffect(() => {
    scrollToBottom()
  }, [messages, steps, isQuerying, divRef.current])

  const handleSubmit = useCallback(
    (message: string) => {
      if (!message.trim() || isQuerying) {
        return
      }
      dispatch(chatActions.setQuery({ chatId, query: message }))
    },
    [isQuerying]
  )

  if (!chat) {
    return <></>
  }

  return (
    <div className="flex flex-col">
      <div className="flex-grow">
        <div role="list" className="space-y-6 p-8">
          <MessageThread chatId={chatId} showName={true} />
        </div>
        <div ref={divRef}></div>
      </div>

      <div className="flex flex-row p-4 px-10 pb-5 justify-center">
        <div className="flex-grow max-w-3xl">
          <PromptInput
            isQuerying={isQuerying}
            handleSubmit={handleSubmit}
            backgroundColor="bg-white"
          />
        </div>
      </div>
    </div>
  )
}

export default Chat
