import React, { useState, useRef, useCallback } from 'react'

import clsx from 'clsx'
import { Send } from '@mui/icons-material'

interface PromptInputProps {
  placeholder?: string
  backgroundColor?: string
  handleSubmit: (message: string) => void
  isQuerying: boolean
}

const PromptInput = ({
  handleSubmit,
  isQuerying,
  placeholder = 'Enter a prompt here',
  backgroundColor = 'bg-[rgb(240,244,249)]',
}: PromptInputProps) => {
  const [inputText, setInputText] = useState('')
  const inputRef = useRef(null)

  const handleInputChange = (e: any) => {
    setInputText(e.target.value)
  }

  const onSubmit = useCallback(() => {
    handleSubmit(inputText)
    setInputText('')
    inputRef.current.focus()
  }, [isQuerying, inputText])

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && e.keyCode !== 229) {
      onSubmit()
    }
  }

  return (
    <div
      className={clsx(
        'relative flex items-center rounded-full p-2 shadow',
        backgroundColor,
        'focus-within:ring-1 focus-within:ring-blue-300 focus-within:ring-opacity-50'
      )}
    >
      <input
        ref={inputRef}
        type="text"
        value={inputText}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        disabled={isQuerying}
        placeholder={placeholder}
        className={`flex-grow bg-transparent border-0 ring-0 focus:ring-0 placeholder-gray-400 outline-none pl-4 ${
          isQuerying ? 'cursor-not-allowed text-gray-500' : 'cursor-text text-gray-800'
        }`}
      />
      <div className="flex items-center space-x-2">
        <button
          onClick={onSubmit}
          disabled={isQuerying || !inputText.trim()}
          className={clsx(
            'p-2 text-white border-0  rounded-full transition-all duration-300 ease-in-out flex flex-row items-center justify-center',
            inputText.trim() ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-400',
            isQuerying ? 'animate-spin' : ''
          )}
        >
          {isQuerying ? (
            <div className="size-4 border-white border-t-2 rounded-full animate-spin"></div>
          ) : (
            <div className="size-4 flex items-center justify-center">
              <Send fontSize="inherit" />
            </div>
          )}
        </button>
      </div>
    </div>
  )
}

export default PromptInput
