export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  BigInt: { input: any; output: any }
  Date: { input: any; output: any }
  ISO8601DateTime: { input: any; output: any }
  JSON: { input: any; output: any }
  Upload: { input: any; output: any }
}

export enum ActorEnum {
  /** System actor */
  System = 'SYSTEM',
  /** User actor */
  User = 'USER',
  /** Workflow actor */
  Workflow = 'WORKFLOW',
}

export type Agent = {
  __typename?: 'Agent'
  backend: Scalars['String']['output']
  buildStatus?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: User
  dataSets?: Maybe<Array<DataSet>>
  description: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  isPublic?: Maybe<Scalars['Boolean']['output']>
  jobTitle: Scalars['String']['output']
  label: Scalars['String']['output']
  plugins?: Maybe<Array<Plugin>>
  primer: Scalars['String']['output']
  profileImageUrl?: Maybe<Scalars['String']['output']>
  prompts?: Maybe<Array<Scalars['String']['output']>>
  shortDescription: Scalars['String']['output']
  skillSets?: Maybe<Array<SkillSet>>
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  uuid: Scalars['String']['output']
  walkthroughs?: Maybe<Array<Walkthrough>>
}

export type AgentCreate = {
  __typename?: 'AgentCreate'
  agent?: Maybe<Agent>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type AgentFile = {
  __typename?: 'AgentFile'
  filePath?: Maybe<Scalars['String']['output']>
  fileUuid?: Maybe<Scalars['String']['output']>
  value: Scalars['String']['output']
}

export type AgentKnowledge = {
  __typename?: 'AgentKnowledge'
  content: Scalars['String']['output']
  documentType: Scalars['String']['output']
  source: Scalars['String']['output']
  sourceName: Scalars['String']['output']
  sourceType: Scalars['String']['output']
  sourceUuid: Scalars['String']['output']
  uuid: Scalars['String']['output']
}

export type Analysis = {
  __typename?: 'Analysis'
  agent: Agent
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: User
  cronSchedule?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: Scalars['String']['output']
  synthesis: Scalars['String']['output']
  tasks: Array<Task>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['ID']['output']
  webhookUrl?: Maybe<Scalars['String']['output']>
}

export type AnalysisCreateResponse = {
  __typename?: 'AnalysisCreateResponse'
  analysis?: Maybe<Analysis>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type AnalysisRun = {
  __typename?: 'AnalysisRun'
  analysis?: Maybe<Analysis>
  createdAt: Scalars['ISO8601DateTime']['output']
  finishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  messageThreadUuid?: Maybe<Scalars['String']['output']>
  responseMessage?: Maybe<Scalars['String']['output']>
  startedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  synthesis?: Maybe<Scalars['String']['output']>
  tasks: Array<AnalysisTaskResult>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['String']['output']
  webhookResponse?: Maybe<Scalars['JSON']['output']>
  webhookUrl?: Maybe<Scalars['String']['output']>
}

export type AnalysisTaskResult = {
  __typename?: 'AnalysisTaskResult'
  createdAt: Scalars['ISO8601DateTime']['output']
  description: Scalars['String']['output']
  name: Scalars['String']['output']
  response?: Maybe<Array<MessageContentUnion>>
  task?: Maybe<Scalars['String']['output']>
}

export type Audio = {
  __typename?: 'Audio'
  audioUrl?: Maybe<Scalars['String']['output']>
  data?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  uuid: Scalars['String']['output']
}

export type CodeInterpret = {
  __typename?: 'CodeInterpret'
  input?: Maybe<Scalars['String']['output']>
  outputs?: Maybe<Scalars['JSON']['output']>
}

export type CodeInterpreterToolCall = {
  __typename?: 'CodeInterpreterToolCall'
  codeInterpreter: CodeInterpret
  description: Scalars['String']['output']
  name: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type CompanyAgent = {
  __typename?: 'CompanyAgent'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  uuid: Scalars['String']['output']
}

export type Content = SkillSet | Walkthrough

/** All available sources */
export enum ContentType {
  Complete = 'Complete',
  Incomplete = 'Incomplete',
  SkillSet = 'SkillSet',
  Walkthrough = 'Walkthrough',
}

export type CreditUsage = {
  __typename?: 'CreditUsage'
  quantity: Scalars['Int']['output']
  usageDate: Scalars['ISO8601DateTime']['output']
}

export type DataCredential = {
  __typename?: 'DataCredential'
  credentialType: Scalars['String']['output']
  credentials: Scalars['JSON']['output']
  errors?: Maybe<Array<Scalars['String']['output']>>
  uuid: Scalars['String']['output']
}

export type DataSet = {
  __typename?: 'DataSet'
  agents?: Maybe<Array<Agent>>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy?: Maybe<User>
  dataSample?: Maybe<Scalars['String']['output']>
  definition: Scalars['JSON']['output']
  description: Scalars['String']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  organization?: Maybe<Organization>
  status?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['String']['output']
}

export type DataSetCreateResponse = {
  __typename?: 'DataSetCreateResponse'
  dataSet?: Maybe<DataSet>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success?: Maybe<Scalars['Boolean']['output']>
}

/** All available destination types */
export enum DestinationType {
  Organization = 'organization',
  RemoveFromSkillSet = 'remove_from_skill_set',
  SkillSet = 'skill_set',
  User = 'user',
}

export type EvaluationMessage = {
  __typename?: 'EvaluationMessage'
  reason?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['Boolean']['output']>
}

/** All available event subject types */
export enum EventSubjectType {
  Skillset = 'skillset',
  Walkthrough = 'walkthrough',
}

export type FeedItem = Agent | AnalysisRun | Suggestion | TaskRun | Workflow

/** All available types */
export enum FeedItemTypeEnum {
  /** Agent */
  Agent = 'AGENT',
  /** Analysis Run */
  AnalysisRun = 'ANALYSIS_RUN',
  /** Suggestion */
  Suggestion = 'SUGGESTION',
  /** Task Run */
  TaskRun = 'TASK_RUN',
  /** Workflow */
  Workflow = 'WORKFLOW',
}

export enum FeedbackEnum {
  /** Thumbs down feedback */
  ThumbsDown = 'THUMBS_DOWN',
  /** Thumbs up feedback */
  ThumbsUp = 'THUMBS_UP',
}

export type FileSearchToolCall = {
  __typename?: 'FileSearchToolCall'
  description: Scalars['String']['output']
  name: Scalars['String']['output']
  raw: Scalars['JSON']['output']
  type: Scalars['String']['output']
}

export type FunctionToolCall = {
  __typename?: 'FunctionToolCall'
  description: Scalars['String']['output']
  function: ToolFunction
  name: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type Gif = {
  __typename?: 'Gif'
  data?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export type ImageContent = {
  __typename?: 'ImageContent'
  type: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type ImageData = {
  __typename?: 'ImageData'
  assetId: Scalars['String']['output']
  bytes: Scalars['String']['output']
  format: Scalars['String']['output']
  height: Scalars['Int']['output']
  publicId: Scalars['String']['output']
  url: Scalars['String']['output']
  version: Scalars['String']['output']
  width: Scalars['Int']['output']
}

export type LearningActivity = {
  __typename?: 'LearningActivity'
  action: LearningActivityActionType
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  objectId: Scalars['ID']['output']
  objectType: LearningActivityObjectType
  subjectId: Scalars['ID']['output']
  subjectType?: Maybe<LearningActivitySubjectType>
  updatedAt: Scalars['Date']['output']
}

/** All available types */
export enum LearningActivityActionType {
  Completed = 'completed',
  Started = 'started',
  Viewed = 'viewed',
  ViewedShared = 'viewed_shared',
}

/** All available types */
export enum LearningActivityObjectType {
  SkillSet = 'skill_set',
  Walkthrough = 'walkthrough',
}

/** All available types */
export enum LearningActivitySubjectType {
  User = 'user',
}

/** Content can be of type Text, Image, or File */
export type MessageContentUnion = ImageContent | TextContent

export type MessageFeedback = {
  __typename?: 'MessageFeedback'
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type MessageHistory = {
  __typename?: 'MessageHistory'
  actor: ActorEnum
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Milliseconds since epoch */
  createdAtMilli: Scalars['BigInt']['output']
  feedback?: Maybe<FeedbackEnum>
  id: Scalars['Int']['output']
  message: Array<MessageContentUnion>
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** Milliseconds since epoch */
  updatedAtMilli: Scalars['BigInt']['output']
  uuid: Scalars['String']['output']
}

export type MessageResponse = {
  __typename?: 'MessageResponse'
  errors: Array<Scalars['String']['output']>
  threadUuid?: Maybe<Scalars['String']['output']>
}

export type MessageThread = {
  __typename?: 'MessageThread'
  agent: Agent
  createdAt: Scalars['ISO8601DateTime']['output']
  messages: Array<MessageHistory>
  steps: Array<StepHistory>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['String']['output']
}

export type MetaData = {
  __typename?: 'MetaData'
  count: Scalars['Int']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  addDataSetToAgent: ResponseStatus
  addPlugin: ResponseStatus
  addSkillSetToAgent: ResponseStatus
  addToWaitlist: WaitlistResponse
  addWalkthroughToAgent: ResponseStatus
  createAgent: AgentCreate
  createAnalysis: AnalysisCreateResponse
  createDataCredentials: ResponseStatus
  createDataSet: DataSetCreateResponse
  createEvent: RrwebEvents
  createLearningActivity: LearningActivity
  createSkillSet: SkillSet
  createTask: ResponseStatus
  createUser: User
  createUserActivity: UserActivity
  createWalkthrough: Walkthrough
  createWalkthroughV2: TemporaryWalkthrough
  createWorkflow: WorkflowCreateResponse
  createWorkflowFromTemplate: WorkflowCreateResponse
  deleteAgent: ResponseStatus
  deleteAnalysis: ResponseStatus
  deleteDataCredentials: ResponseStatus
  deleteDataSet: ResponseStatus
  deleteSkillSet: SkillSet
  deleteTask: ResponseStatus
  deleteUser: User
  deleteWalkthrough: Walkthrough
  deleteWorkflow: ResponseStatus
  discardWalkthrough: Walkthrough
  generateProfileImage: ResponseStatus
  messageFeedback: MessageFeedback
  moveSkillSet: SkillSet
  moveWalkthrough: Walkthrough
  publishWalkthrough: Walkthrough
  rebuildAgent: ResponseStatus
  removeDataSetFromAgent: ResponseStatus
  removePlugin: ResponseStatus
  removeSkillSetFromAgent: ResponseStatus
  removeWalkthroughFromAgent: ResponseStatus
  resendEmailInvite: ResponseStatus
  sendAgentMessage: MessageResponse
  sendInvites: ResponseStatus
  shareEmbedWalkthrough: Walkthrough
  shareSkillSet: SkillSet
  shareWalkthrough: Walkthrough
  stopImpersonating: ResponseStatus
  submitRunAnalysis: ResponseStatus
  submitRunTask: ResponseStatus
  submitRunWorkflow: ResponseStatus
  taskRunMarkPassed: ResponseStatus
  toggleSuggestionStatus: ToggleSuggestionStatus
  updateAgent: ResponseStatus
  updateAgentPrompts: ResponseStatus
  updateAnalysis: ResponseStatus
  updateCurrentUser: ResponseStatus
  updateDataCredentials: ResponseStatus
  updateDataSet: ResponseStatus
  updateOrganization: ResponseStatus
  updatePlugin: ResponseStatus
  updateSkillSet: SkillSet
  updateTask: ResponseStatus
  updateUser: User
  updateWalkthrough: Walkthrough
  updateWorkflow: ResponseStatus
  uploadAudio: Audio
  uploadGif: Gif
  uploadGifV2: Response
  uploadRrwebChunk: RrwebEvents
  uploadRrwebChunkV2: Response
  uploadTemporaryWalkthroughAudio: Response
  uploadUserScreenshot: Screenshot
}

export type MutationAddDataSetToAgentArgs = {
  agentUuid: Scalars['String']['input']
  dataSetUuid: Scalars['String']['input']
}

export type MutationAddPluginArgs = {
  agentUuid: Scalars['String']['input']
  data: Scalars['JSON']['input']
  pluginTemplateId: Scalars['String']['input']
}

export type MutationAddSkillSetToAgentArgs = {
  agentUuid: Scalars['String']['input']
  skillSetUuid: Scalars['String']['input']
}

export type MutationAddToWaitlistArgs = {
  email: Scalars['String']['input']
}

export type MutationAddWalkthroughToAgentArgs = {
  agentUuid: Scalars['String']['input']
  walkthroughUuid: Scalars['String']['input']
}

export type MutationCreateAgentArgs = {
  description: Scalars['String']['input']
  firstName: Scalars['String']['input']
  jobTitle: Scalars['String']['input']
  primer: Scalars['String']['input']
  shortDescription: Scalars['String']['input']
}

export type MutationCreateAnalysisArgs = {
  agentUuid: Scalars['String']['input']
  cronSchedule?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  synthesis: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateDataCredentialsArgs = {
  credentialType: Scalars['String']['input']
  credentials: Scalars['JSON']['input']
}

export type MutationCreateDataSetArgs = {
  definition: Scalars['JSON']['input']
  description: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type MutationCreateEventArgs = {
  metadata?: InputMaybe<Scalars['JSON']['input']>
  source?: InputMaybe<Scalars['String']['input']>
  subjectId: Scalars['ID']['input']
  subjectType: EventSubjectType
  timestamp: Scalars['Float']['input']
  type: Scalars['String']['input']
}

export type MutationCreateLearningActivityArgs = {
  action: LearningActivityActionType
  objectId: Scalars['ID']['input']
  objectType: LearningActivityObjectType
  subjectId?: InputMaybe<Scalars['ID']['input']>
  subjectType?: InputMaybe<LearningActivitySubjectType>
}

export type MutationCreateSkillSetArgs = {
  description: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type MutationCreateTaskArgs = {
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  outputWebhookUrl: Scalars['String']['input']
  runSchedule: Scalars['String']['input']
  task: Scalars['String']['input']
  taskType?: InputMaybe<Scalars['String']['input']>
  taskableType: Scalars['String']['input']
  taskableUuid: Scalars['String']['input']
}

export type MutationCreateUserArgs = {
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  organization: Scalars['ID']['input']
  password?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type MutationCreateUserActivityArgs = {
  action: Scalars['String']['input']
  metadata?: InputMaybe<Scalars['JSON']['input']>
  objectId?: InputMaybe<Scalars['ID']['input']>
  objectType?: InputMaybe<UserActivityObjectType>
  url: Scalars['String']['input']
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationCreateWalkthroughArgs = {
  description?: InputMaybe<Scalars['String']['input']>
  meta?: InputMaybe<Scalars['JSON']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  steps?: InputMaybe<Array<Scalars['JSON']['input']>>
}

export type MutationCreateWalkthroughV2Args = {
  description?: InputMaybe<Scalars['String']['input']>
  meta?: InputMaybe<Scalars['JSON']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  steps?: InputMaybe<Array<Scalars['JSON']['input']>>
}

export type MutationCreateWorkflowArgs = {
  agentUuid: Scalars['String']['input']
  cronSchedule?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateWorkflowFromTemplateArgs = {
  agentUuid: Scalars['String']['input']
  templateUuid: Scalars['String']['input']
}

export type MutationDeleteAgentArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteAnalysisArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteDataCredentialsArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteDataSetArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteSkillSetArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteTaskArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteWalkthroughArgs = {
  id: Scalars['ID']['input']
  organizationId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationDeleteWorkflowArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDiscardWalkthroughArgs = {
  uuid: Scalars['ID']['input']
}

export type MutationGenerateProfileImageArgs = {
  uuid: Scalars['String']['input']
}

export type MutationMessageFeedbackArgs = {
  agentUuid: Scalars['String']['input']
  feedback: Scalars['String']['input']
  messageUuid: Scalars['String']['input']
}

export type MutationMoveSkillSetArgs = {
  destinationId?: InputMaybe<Scalars['ID']['input']>
  destinationType: DestinationType
  id: Scalars['ID']['input']
}

export type MutationMoveWalkthroughArgs = {
  destinationId?: InputMaybe<Scalars['ID']['input']>
  destinationType: DestinationType
  id: Scalars['ID']['input']
}

export type MutationPublishWalkthroughArgs = {
  uuid: Scalars['ID']['input']
}

export type MutationRebuildAgentArgs = {
  uuid: Scalars['String']['input']
}

export type MutationRemoveDataSetFromAgentArgs = {
  agentUuid: Scalars['String']['input']
  dataSetUuid: Scalars['String']['input']
}

export type MutationRemovePluginArgs = {
  id: Scalars['Int']['input']
}

export type MutationRemoveSkillSetFromAgentArgs = {
  agentUuid: Scalars['String']['input']
  skillSetUuid: Scalars['String']['input']
}

export type MutationRemoveWalkthroughFromAgentArgs = {
  agentUuid: Scalars['String']['input']
  walkthroughUuid: Scalars['String']['input']
}

export type MutationResendEmailInviteArgs = {
  id: Scalars['String']['input']
}

export type MutationSendAgentMessageArgs = {
  agentUuid: Scalars['String']['input']
  chatId?: InputMaybe<Scalars['String']['input']>
  enableStreaming?: InputMaybe<Scalars['Boolean']['input']>
  message: Scalars['String']['input']
  threadUuid?: InputMaybe<Scalars['String']['input']>
}

export type MutationSendInvitesArgs = {
  emails: Array<Scalars['String']['input']>
}

export type MutationShareEmbedWalkthroughArgs = {
  enabled: Scalars['Boolean']['input']
  id: Scalars['ID']['input']
}

export type MutationShareSkillSetArgs = {
  id: Scalars['ID']['input']
  shareType: ShareTypeEnum
}

export type MutationShareWalkthroughArgs = {
  id: Scalars['ID']['input']
  shareType: ShareTypeEnum
}

export type MutationSubmitRunAnalysisArgs = {
  uuid: Scalars['String']['input']
}

export type MutationSubmitRunTaskArgs = {
  uuid: Scalars['String']['input']
}

export type MutationSubmitRunWorkflowArgs = {
  uuid: Scalars['String']['input']
}

export type MutationTaskRunMarkPassedArgs = {
  uuid: Scalars['String']['input']
}

export type MutationToggleSuggestionStatusArgs = {
  uuid: Scalars['String']['input']
}

export type MutationUpdateAgentArgs = {
  description: Scalars['String']['input']
  firstName: Scalars['String']['input']
  jobTitle: Scalars['String']['input']
  primer: Scalars['String']['input']
  shortDescription: Scalars['String']['input']
  uuid: Scalars['String']['input']
}

export type MutationUpdateAgentPromptsArgs = {
  prompts: Array<Scalars['String']['input']>
  uuid: Scalars['String']['input']
}

export type MutationUpdateAnalysisArgs = {
  agentUuid: Scalars['String']['input']
  cronSchedule?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  synthesis: Scalars['String']['input']
  uuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpdateCurrentUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  privacyPolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateDataCredentialsArgs = {
  credentials: Scalars['JSON']['input']
  uuid: Scalars['String']['input']
}

export type MutationUpdateDataSetArgs = {
  definition: Scalars['JSON']['input']
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  uuid: Scalars['String']['input']
}

export type MutationUpdateOrganizationArgs = {
  id: Scalars['Int']['input']
  name: Scalars['String']['input']
}

export type MutationUpdatePluginArgs = {
  config: Scalars['JSON']['input']
  id: Scalars['Int']['input']
}

export type MutationUpdateSkillSetArgs = {
  description: Scalars['String']['input']
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type MutationUpdateTaskArgs = {
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  outputWebhookUrl: Scalars['String']['input']
  runSchedule: Scalars['String']['input']
  task: Scalars['String']['input']
  taskType?: InputMaybe<Scalars['String']['input']>
  uuid: Scalars['String']['input']
}

export type MutationUpdateUserArgs = {
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  organization?: InputMaybe<Scalars['ID']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type MutationUpdateWalkthroughArgs = {
  audioEnabled?: InputMaybe<Scalars['Boolean']['input']>
  description: Scalars['String']['input']
  id: Scalars['ID']['input']
  labs?: InputMaybe<Scalars['Boolean']['input']>
  liveLearning?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  steps?: InputMaybe<Array<Scalars['JSON']['input']>>
  synthesis?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpdateWorkflowArgs = {
  agentUuid: Scalars['String']['input']
  cronSchedule?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  uuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
}

export type MutationUploadAudioArgs = {
  file: Scalars['Upload']['input']
  walkthroughId: Scalars['ID']['input']
}

export type MutationUploadGifArgs = {
  urls: Array<Scalars['String']['input']>
  walkthroughId: Scalars['ID']['input']
}

export type MutationUploadGifV2Args = {
  urls: Array<Scalars['String']['input']>
  walkthroughUuid: Scalars['ID']['input']
}

export type MutationUploadRrwebChunkArgs = {
  file: Scalars['Upload']['input']
  index: Scalars['Int']['input']
  walkthroughId: Scalars['ID']['input']
}

export type MutationUploadRrwebChunkV2Args = {
  file: Scalars['Upload']['input']
  index: Scalars['Int']['input']
  walkthroughUuid: Scalars['ID']['input']
}

export type MutationUploadTemporaryWalkthroughAudioArgs = {
  file: Scalars['Upload']['input']
  walkthroughUuid: Scalars['ID']['input']
}

export type MutationUploadUserScreenshotArgs = {
  publicId: Scalars['String']['input']
  screenshot: Scalars['Upload']['input']
}

export type Organization = {
  __typename?: 'Organization'
  createdAt: Scalars['Date']['output']
  emailNamespace?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isPaid: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  restrictByGroupOrRole: Scalars['Boolean']['output']
  updatedAt: Scalars['Date']['output']
  userCount?: Maybe<Scalars['Int']['output']>
  users: Array<User>
}

export type Plugin = {
  __typename?: 'Plugin'
  config: Scalars['JSON']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  pluginTemplate?: Maybe<PluginTemplate>
  pluginType: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type PluginTemplate = {
  __typename?: 'PluginTemplate'
  dataTemplate: Scalars['JSON']['output']
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  onePerAgent: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  useCases: Array<Scalars['String']['output']>
}

export type Query = {
  __typename?: 'Query'
  CurrentUser?: Maybe<User>
  SkillSet: SkillSet
  User: User
  Walkthrough: Walkthrough
  /** Find an agent by UUID */
  agent?: Maybe<Agent>
  agentAnalyses?: Maybe<Array<Analysis>>
  /** Find all agents that use this data set */
  agentDataSets?: Maybe<Array<DataSet>>
  /** Find all knowledge for an agent */
  agentKnowledge?: Maybe<Array<AgentKnowledge>>
  /** Find a knowledge item for an agent */
  agentKnowledgeItem?: Maybe<AgentKnowledge>
  /** Find all suggestions for an agent */
  agentSuggestions?: Maybe<Array<Suggestion>>
  agentWorkflows?: Maybe<Array<Workflow>>
  allRoles: Array<Role>
  analyses?: Maybe<Array<Analysis>>
  analysis?: Maybe<Analysis>
  analysisTasks?: Maybe<Array<Task>>
  assignedToMe: Array<SkillSet>
  assignedToMeMeta: MetaData
  /** Find the company agent */
  companyAgent?: Maybe<CompanyAgent>
  completed: Array<Content>
  completedMeta: MetaData
  creditUsages?: Maybe<Array<CreditUsage>>
  /** Looker credentials for the current organization */
  dataCredentials?: Maybe<Array<DataCredential>>
  /** Find a dataset by UUID */
  dataSet?: Maybe<DataSet>
  /** Find all demo agents */
  demoAgents?: Maybe<Array<Agent>>
  feedItems?: Maybe<Array<FeedItem>>
  galleryContents: Array<Content>
  galleryContentsMeta: MetaData
  /** Get the message in a thread by UUID */
  getMessageHistories?: Maybe<Array<MessageHistory>>
  /** Get the message thread */
  getMessageThread?: Maybe<MessageThread>
  /** Get the message threads */
  getMessageThreads?: Maybe<Array<MessageThread>>
  /** Get the steps in a thread by UUID */
  getStepHistories?: Maybe<Array<StepHistory>>
  historicAnalysisRuns?: Maybe<Array<AnalysisRun>>
  lastAnalysisRun?: Maybe<AnalysisRun>
  myLibrary: Array<Content>
  myLibraryMeta: MetaData
  oneAnalysisRun?: Maybe<AnalysisRun>
  /** Find all agents for an organization */
  organizationAgents?: Maybe<Array<Agent>>
  /** Find all data sets for an organization */
  organizationDataSets?: Maybe<Array<DataSet>>
  organizationLibrary: Array<Content>
  organizationLibraryMeta: MetaData
  organizationSuggestions?: Maybe<Array<Suggestion>>
  organizationUsers: Array<User>
  organizationUsersMeta: MetaData
  /** Get all the plugin templates */
  pluginTemplates?: Maybe<Array<PluginTemplate>>
  /** Get all the plugins for an agent */
  plugins?: Maybe<Array<Plugin>>
  recentlyViewed: Array<Content>
  recentlyViewedMeta: MetaData
  sharedWithMe: Array<Content>
  sharedWithMeMeta: MetaData
  task?: Maybe<Task>
  taskRuns?: Maybe<Array<TaskRun>>
  tasks?: Maybe<Array<Task>>
  trueUser?: Maybe<User>
  walkthroughByUuid: Walkthrough
  workflow?: Maybe<Workflow>
  workflowTasks?: Maybe<Array<Task>>
  workflowTemplates?: Maybe<Array<WorkflowTemplate>>
  workflows?: Maybe<Array<Workflow>>
}

export type QuerySkillSetArgs = {
  uuid: Scalars['ID']['input']
}

export type QueryUserArgs = {
  id: Scalars['ID']['input']
}

export type QueryWalkthroughArgs = {
  id: Scalars['ID']['input']
  organizationId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryAgentArgs = {
  uuid: Scalars['String']['input']
}

export type QueryAgentAnalysesArgs = {
  agentUuid: Scalars['ID']['input']
}

export type QueryAgentDataSetsArgs = {
  uuid: Scalars['String']['input']
}

export type QueryAgentKnowledgeArgs = {
  uuid: Scalars['String']['input']
}

export type QueryAgentKnowledgeItemArgs = {
  agentUuid: Scalars['String']['input']
  itemUuid: Scalars['String']['input']
}

export type QueryAgentSuggestionsArgs = {
  agentUuid: Scalars['String']['input']
}

export type QueryAgentWorkflowsArgs = {
  agentUuid: Scalars['ID']['input']
}

export type QueryAnalysesArgs = {
  agentUuid?: InputMaybe<Scalars['ID']['input']>
}

export type QueryAnalysisArgs = {
  uuid: Scalars['ID']['input']
}

export type QueryAnalysisTasksArgs = {
  analysisUuid: Scalars['ID']['input']
}

export type QueryAssignedToMeArgs = {
  contentType?: InputMaybe<ContentType>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

export type QueryAssignedToMeMetaArgs = {
  contentType?: InputMaybe<ContentType>
}

export type QueryCompletedArgs = {
  contentType?: InputMaybe<ContentType>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

export type QueryCompletedMetaArgs = {
  contentType?: InputMaybe<ContentType>
}

export type QueryCreditUsagesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryDataSetArgs = {
  uuid: Scalars['String']['input']
}

export type QueryFeedItemsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  types?: InputMaybe<Array<FeedItemTypeEnum>>
}

export type QueryGalleryContentsArgs = {
  contentType?: InputMaybe<ContentType>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

export type QueryGalleryContentsMetaArgs = {
  contentType?: InputMaybe<ContentType>
}

export type QueryGetMessageHistoriesArgs = {
  uuid: Scalars['String']['input']
}

export type QueryGetMessageThreadArgs = {
  uuid: Scalars['String']['input']
}

export type QueryGetMessageThreadsArgs = {
  agentUuid: Scalars['String']['input']
}

export type QueryGetStepHistoriesArgs = {
  uuid: Scalars['String']['input']
}

export type QueryHistoricAnalysisRunsArgs = {
  uuid: Scalars['ID']['input']
}

export type QueryLastAnalysisRunArgs = {
  uuid: Scalars['ID']['input']
}

export type QueryMyLibraryArgs = {
  contentType?: InputMaybe<ContentType>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

export type QueryMyLibraryMetaArgs = {
  contentType?: InputMaybe<ContentType>
}

export type QueryOneAnalysisRunArgs = {
  uuid: Scalars['ID']['input']
}

export type QueryOrganizationLibraryArgs = {
  contentType?: InputMaybe<ContentType>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

export type QueryOrganizationLibraryMetaArgs = {
  contentType?: InputMaybe<ContentType>
}

export type QueryOrganizationUsersArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
}

export type QueryOrganizationUsersMetaArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPluginsArgs = {
  agentUuid: Scalars['String']['input']
}

export type QueryRecentlyViewedArgs = {
  contentType?: InputMaybe<ContentType>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

export type QueryRecentlyViewedMetaArgs = {
  contentType?: InputMaybe<ContentType>
}

export type QuerySharedWithMeArgs = {
  contentType?: InputMaybe<ContentType>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

export type QuerySharedWithMeMetaArgs = {
  contentType?: InputMaybe<ContentType>
}

export type QueryTaskArgs = {
  uuid: Scalars['ID']['input']
}

export type QueryTaskRunsArgs = {
  taskUuid: Scalars['ID']['input']
}

export type QueryTasksArgs = {
  taskableType: Scalars['String']['input']
  taskableUuid: Scalars['ID']['input']
}

export type QueryWalkthroughByUuidArgs = {
  uuid: Scalars['ID']['input']
}

export type QueryWorkflowArgs = {
  uuid: Scalars['ID']['input']
}

export type QueryWorkflowTasksArgs = {
  workflowUuid: Scalars['ID']['input']
}

export type Response = {
  __typename?: 'Response'
  data?: Maybe<Scalars['String']['output']>
}

export type ResponseStatus = {
  __typename?: 'ResponseStatus'
  errors?: Maybe<Array<Scalars['String']['output']>>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type Role = {
  __typename?: 'Role'
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
}

export type RrwebEvents = {
  __typename?: 'RrwebEvents'
  data?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export type Screenshot = {
  __typename?: 'Screenshot'
  imageData: ImageData
}

/** All available share types */
export enum ShareTypeEnum {
  Organization = 'organization',
  Private = 'private',
  Public = 'public',
}

export type SkillSet = {
  __typename?: 'SkillSet'
  assignedUserIds: Array<Scalars['ID']['output']>
  canDelete?: Maybe<Scalars['Boolean']['output']>
  canEdit?: Maybe<Scalars['Boolean']['output']>
  canEditSharing?: Maybe<Scalars['Boolean']['output']>
  canMove?: Maybe<Scalars['Boolean']['output']>
  completed?: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isAssigned?: Maybe<Scalars['Boolean']['output']>
  isInMyLibrary?: Maybe<Scalars['Boolean']['output']>
  isInMyOrganizationLibrary?: Maybe<Scalars['Boolean']['output']>
  isOwner?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  sharedAccess: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
  uuid: Scalars['String']['output']
  walkthroughs?: Maybe<Array<Walkthrough>>
}

export type StepHistory = {
  __typename?: 'StepHistory'
  actor: ActorEnum
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Milliseconds since epoch */
  createdAtMilli: Scalars['BigInt']['output']
  id: Scalars['Int']['output']
  step: Array<StepUnion>
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** Milliseconds since epoch */
  updatedAtMilli: Scalars['BigInt']['output']
  uuid: Scalars['String']['output']
}

/** Possible types for the step field in StepHistory */
export type StepUnion = CodeInterpreterToolCall | FileSearchToolCall | FunctionToolCall

export type Suggestion = {
  __typename?: 'Suggestion'
  agent: Agent
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  body: Scalars['String']['output']
  closedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy?: Maybe<User>
  description?: Maybe<Scalars['String']['output']>
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  organization: Organization
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  status: SuggestionStatusEnum
  steps: Array<Scalars['JSON']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['ID']['output']
}

export enum SuggestionStatusEnum {
  /** Suggestion is closed */
  Closed = 'closed',
  /** Suggestion is open */
  Open = 'open',
}

export type Task = {
  __typename?: 'Task'
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: User
  description: Scalars['String']['output']
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  outputWebhookUrl?: Maybe<Scalars['String']['output']>
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  runSchedule?: Maybe<Scalars['String']['output']>
  status: Scalars['String']['output']
  steps: Array<Scalars['JSON']['output']>
  task: Scalars['String']['output']
  taskRuns?: Maybe<Array<TaskRun>>
  taskType?: Maybe<Scalars['String']['output']>
  taskable: TaskableUnion
  taskableType: Scalars['String']['output']
  taskableUuid: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['ID']['output']
}

export type TaskRun = {
  __typename?: 'TaskRun'
  agent?: Maybe<Agent>
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy?: Maybe<User>
  description?: Maybe<Scalars['String']['output']>
  evaluationMessage?: Maybe<EvaluationMessage>
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  messageHistory?: Maybe<Array<MessageHistory>>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  requestMessage?: Maybe<Scalars['String']['output']>
  responseMessage?: Maybe<Scalars['String']['output']>
  responseStatus?: Maybe<Scalars['String']['output']>
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  runtimeSeconds?: Maybe<Scalars['Int']['output']>
  startedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  state: Scalars['String']['output']
  status: Scalars['String']['output']
  stepHistory?: Maybe<Array<StepHistory>>
  steps: Array<Scalars['JSON']['output']>
  task: Task
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['ID']['output']
  webhookCompletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  webhookResponseStatus?: Maybe<Scalars['String']['output']>
  webhookStatus?: Maybe<Scalars['String']['output']>
}

export type TaskTemplate = {
  __typename?: 'TaskTemplate'
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  description: Scalars['String']['output']
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  runSchedule?: Maybe<Scalars['String']['output']>
  steps: Array<Scalars['JSON']['output']>
  task: Scalars['String']['output']
  taskType: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
  uuid: Scalars['String']['output']
}

/** Objects that can be taskable, either a Workflow or an Analysis */
export type TaskableUnion = Analysis | Workflow

export type TemporaryWalkthrough = {
  __typename?: 'TemporaryWalkthrough'
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  description?: Maybe<Scalars['String']['output']>
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  steps: Array<Scalars['JSON']['output']>
  updatedAt: Scalars['Date']['output']
  uuid: Scalars['String']['output']
}

export type TextContent = {
  __typename?: 'TextContent'
  files?: Maybe<Array<AgentFile>>
  type: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type ToggleSuggestionStatus = {
  __typename?: 'ToggleSuggestionStatus'
  errors?: Maybe<Array<Scalars['String']['output']>>
  status?: Maybe<Scalars['String']['output']>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type ToolFunction = {
  __typename?: 'ToolFunction'
  arguments: Scalars['JSON']['output']
  name: Scalars['String']['output']
  output: Scalars['String']['output']
}

export type User = {
  __typename?: 'User'
  createdAt: Scalars['Date']['output']
  currentSignInAt?: Maybe<Scalars['Date']['output']>
  email: Scalars['String']['output']
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isInvitedPendingRegistration: Scalars['Boolean']['output']
  lastName?: Maybe<Scalars['String']['output']>
  lastSignInAt?: Maybe<Scalars['Date']['output']>
  longName: Scalars['String']['output']
  organization: Organization
  privacyPolicyAccepted: Scalars['Boolean']['output']
  roles: Array<Role>
  shortName: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
}

export type UserActivity = {
  __typename?: 'UserActivity'
  action: Scalars['String']['output']
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  metadata?: Maybe<Scalars['JSON']['output']>
  object?: Maybe<UserActivityObject>
  objectId?: Maybe<Scalars['ID']['output']>
  objectType?: Maybe<UserActivityObjectType>
  updatedAt: Scalars['Date']['output']
  url: Scalars['String']['output']
  userId: Scalars['ID']['output']
}

export type UserActivityObject = SkillSet | Walkthrough

/** All available user activity object types */
export enum UserActivityObjectType {
  Skillset = 'skillset',
  Walkthrough = 'walkthrough',
}

export type WaitlistResponse = {
  __typename?: 'WaitlistResponse'
  errors: Array<Scalars['String']['output']>
  placeInLine: Scalars['Int']['output']
  success: Scalars['Boolean']['output']
}

export type Walkthrough = {
  __typename?: 'Walkthrough'
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  canDelete?: Maybe<Scalars['Boolean']['output']>
  canEdit?: Maybe<Scalars['Boolean']['output']>
  canEditSharing?: Maybe<Scalars['Boolean']['output']>
  canMove?: Maybe<Scalars['Boolean']['output']>
  completed?: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  description?: Maybe<Scalars['String']['output']>
  embedEnabled: Scalars['Boolean']['output']
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  interactionCount: Scalars['Int']['output']
  isInMyLibrary?: Maybe<Scalars['Boolean']['output']>
  isInMyOrganizationLibrary?: Maybe<Scalars['Boolean']['output']>
  isOwner?: Maybe<Scalars['Boolean']['output']>
  isReadonly: Scalars['Boolean']['output']
  labs?: Maybe<Scalars['Boolean']['output']>
  lastSynthesisAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  liveLearning?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  sharedAccess: Scalars['String']['output']
  skillSet?: Maybe<SkillSet>
  steps: Array<Scalars['JSON']['output']>
  summary?: Maybe<Scalars['String']['output']>
  synthesis?: Maybe<Scalars['String']['output']>
  synthesisStatus?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['Date']['output']
  uuid: Scalars['String']['output']
  viewCount: Scalars['Int']['output']
}

export type Workflow = {
  __typename?: 'Workflow'
  agent: Agent
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: User
  cronSchedule?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  steps: Array<Scalars['JSON']['output']>
  tasks: Array<Task>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['ID']['output']
  webhookUrl?: Maybe<Scalars['String']['output']>
}

export type WorkflowCreateResponse = {
  __typename?: 'WorkflowCreateResponse'
  errors?: Maybe<Array<Scalars['String']['output']>>
  success?: Maybe<Scalars['Boolean']['output']>
  workflow?: Maybe<Workflow>
}

export type WorkflowTemplate = {
  __typename?: 'WorkflowTemplate'
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  description: Scalars['String']['output']
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  steps: Array<Scalars['JSON']['output']>
  tasks: Array<TaskTemplate>
  updatedAt: Scalars['Date']['output']
  uuid: Scalars['ID']['output']
}

export type OrganizationAgentsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationAgentsQuery = {
  __typename?: 'Query'
  organizationAgents?: Array<{
    __typename?: 'Agent'
    firstName: string
    label: string
    jobTitle: string
    profileImageUrl?: string | null
    shortDescription: string
    description: string
    primer: string
    backend: string
    uuid: string
    prompts?: Array<string> | null
    id: string
    isPublic?: boolean | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    plugins?: Array<{
      __typename?: 'Plugin'
      id: number
      name: string
      pluginType: string
      config: any
      pluginTemplate?: {
        __typename?: 'PluginTemplate'
        id: string
        name: string
        type: string
        description: string
        useCases: Array<string>
        dataTemplate: any
        onePerAgent: boolean
      } | null
    }> | null
    walkthroughs?: Array<{
      __typename?: 'Walkthrough'
      id: string
      uuid: string
      name: string
      description?: string | null
      steps: Array<any>
      meta?: any | null
      audioEnabled: boolean
      hasAudio: boolean
      canEdit?: boolean | null
      canMove?: boolean | null
      isOwner?: boolean | null
      isInMyLibrary?: boolean | null
      isInMyOrganizationLibrary?: boolean | null
      canEditSharing?: boolean | null
      canDelete?: boolean | null
      sharedAccess: string
      completed?: boolean | null
      createdAt: any
      viewCount: number
      interactionCount: number
      rrwebEventsUrl?: string | null
      audioUrl?: string | null
      liveLearning?: boolean | null
      labs?: boolean | null
      embedEnabled: boolean
      createdBy?: {
        __typename?: 'User'
        firstName?: string | null
        lastName?: string | null
        id: string
      } | null
      skillSet?: {
        __typename?: 'SkillSet'
        id: string
        name: string
        uuid: string
        sharedAccess: string
      } | null
    }> | null
    dataSets?: Array<{
      __typename?: 'DataSet'
      id: number
      uuid: string
      name: string
      dataSample?: string | null
      status?: string | null
      description: string
      definition: any
      createdAt: any
      updatedAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    }> | null
    skillSets?: Array<{ __typename?: 'SkillSet'; id: string; uuid: string; name: string }> | null
  }> | null
}

export type SendAgentMessageMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  threadUuid?: InputMaybe<Scalars['String']['input']>
  message: Scalars['String']['input']
  chatId: Scalars['String']['input']
  enableStreaming: Scalars['Boolean']['input']
}>

export type SendAgentMessageMutation = {
  __typename?: 'Mutation'
  sendAgentMessage: {
    __typename?: 'MessageResponse'
    errors: Array<string>
    threadUuid?: string | null
  }
}

export type MessageFeedbackMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  messageUuid: Scalars['String']['input']
  feedback: Scalars['String']['input']
}>

export type MessageFeedbackMutation = {
  __typename?: 'Mutation'
  messageFeedback: { __typename?: 'MessageFeedback'; errors: Array<string>; success: boolean }
}

export type CreateAgentMutationVariables = Exact<{
  firstName: Scalars['String']['input']
  jobTitle: Scalars['String']['input']
  shortDescription: Scalars['String']['input']
  description: Scalars['String']['input']
  primer: Scalars['String']['input']
}>

export type CreateAgentMutation = {
  __typename?: 'Mutation'
  createAgent: {
    __typename?: 'AgentCreate'
    success: boolean
    errors: Array<string>
    agent?: {
      __typename?: 'Agent'
      firstName: string
      label: string
      jobTitle: string
      profileImageUrl?: string | null
      shortDescription: string
      description: string
      primer: string
      uuid: string
      backend: string
    } | null
  }
}

export type AddWalkthroughToAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  walkthroughUuid: Scalars['String']['input']
}>

export type AddWalkthroughToAgentMutation = {
  __typename?: 'Mutation'
  addWalkthroughToAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type RemoveWalkthroughFromAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  walkthroughUuid: Scalars['String']['input']
}>

export type RemoveWalkthroughFromAgentMutation = {
  __typename?: 'Mutation'
  removeWalkthroughFromAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AddSkillSetToAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  skillSetUuid: Scalars['String']['input']
}>

export type AddSkillSetToAgentMutation = {
  __typename?: 'Mutation'
  addSkillSetToAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type RemoveSkillSetFromAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  skillSetUuid: Scalars['String']['input']
}>

export type RemoveSkillSetFromAgentMutation = {
  __typename?: 'Mutation'
  removeSkillSetFromAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateAgentMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  firstName: Scalars['String']['input']
  jobTitle: Scalars['String']['input']
  shortDescription: Scalars['String']['input']
  description: Scalars['String']['input']
  primer: Scalars['String']['input']
}>

export type UpdateAgentMutation = {
  __typename?: 'Mutation'
  updateAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AgentQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type AgentQuery = {
  __typename?: 'Query'
  agent?: {
    __typename?: 'Agent'
    firstName: string
    label: string
    jobTitle: string
    profileImageUrl?: string | null
    shortDescription: string
    description: string
    primer: string
    backend: string
    uuid: string
    prompts?: Array<string> | null
    id: string
    isPublic?: boolean | null
    createdAt: any
    buildStatus?: string | null
    createdBy: {
      __typename?: 'User'
      firstName?: string | null
      lastName?: string | null
      id: string
    }
    plugins?: Array<{
      __typename?: 'Plugin'
      id: number
      name: string
      pluginType: string
      config: any
      pluginTemplate?: {
        __typename?: 'PluginTemplate'
        id: string
        name: string
        type: string
        description: string
        useCases: Array<string>
        dataTemplate: any
        onePerAgent: boolean
      } | null
    }> | null
    walkthroughs?: Array<{
      __typename?: 'Walkthrough'
      id: string
      uuid: string
      name: string
      description?: string | null
      steps: Array<any>
      meta?: any | null
      audioEnabled: boolean
      hasAudio: boolean
      canEdit?: boolean | null
      canMove?: boolean | null
      isOwner?: boolean | null
      isInMyLibrary?: boolean | null
      isInMyOrganizationLibrary?: boolean | null
      canEditSharing?: boolean | null
      canDelete?: boolean | null
      sharedAccess: string
      completed?: boolean | null
      createdAt: any
      viewCount: number
      interactionCount: number
      rrwebEventsUrl?: string | null
      audioUrl?: string | null
      liveLearning?: boolean | null
      labs?: boolean | null
      embedEnabled: boolean
      createdBy?: {
        __typename?: 'User'
        firstName?: string | null
        lastName?: string | null
        id: string
      } | null
      skillSet?: {
        __typename?: 'SkillSet'
        id: string
        name: string
        uuid: string
        sharedAccess: string
      } | null
    }> | null
    skillSets?: Array<{
      __typename?: 'SkillSet'
      id: string
      uuid: string
      name: string
      description?: string | null
    }> | null
  } | null
}

export type DeleteAgentMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteAgentMutation = {
  __typename?: 'Mutation'
  deleteAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type PluginTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type PluginTemplatesQuery = {
  __typename?: 'Query'
  pluginTemplates?: Array<{
    __typename?: 'PluginTemplate'
    id: string
    name: string
    type: string
    description: string
    useCases: Array<string>
    dataTemplate: any
    onePerAgent: boolean
  }> | null
}

export type AddPluginMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  pluginTemplateId: Scalars['String']['input']
  data: Scalars['JSON']['input']
}>

export type AddPluginMutation = {
  __typename?: 'Mutation'
  addPlugin: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type PluginsQueryVariables = Exact<{
  agentUuid: Scalars['String']['input']
}>

export type PluginsQuery = {
  __typename?: 'Query'
  plugins?: Array<{
    __typename?: 'Plugin'
    id: number
    name: string
    pluginType: string
    config: any
    pluginTemplate?: {
      __typename?: 'PluginTemplate'
      id: string
      name: string
      type: string
      description: string
      useCases: Array<string>
      dataTemplate: any
      onePerAgent: boolean
    } | null
  }> | null
}

export type RemovePluginMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type RemovePluginMutation = {
  __typename?: 'Mutation'
  removePlugin: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdatePluginMutationVariables = Exact<{
  id: Scalars['Int']['input']
  config: Scalars['JSON']['input']
}>

export type UpdatePluginMutation = {
  __typename?: 'Mutation'
  updatePlugin: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateAgentPromptsMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  prompts: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type UpdateAgentPromptsMutation = {
  __typename?: 'Mutation'
  updateAgentPrompts: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type GenerateProfileImageMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type GenerateProfileImageMutation = {
  __typename?: 'Mutation'
  generateProfileImage: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type RebuildAgentMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type RebuildAgentMutation = {
  __typename?: 'Mutation'
  rebuildAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AgentKnowledgeQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type AgentKnowledgeQuery = {
  __typename?: 'Query'
  agentKnowledge?: Array<{
    __typename?: 'AgentKnowledge'
    documentType: string
    uuid: string
    sourceType: string
    sourceName: string
    sourceUuid: string
  }> | null
}

export type AgentKnowledgeItemQueryVariables = Exact<{
  agentUuid: Scalars['String']['input']
  itemUuid: Scalars['String']['input']
}>

export type AgentKnowledgeItemQuery = {
  __typename?: 'Query'
  agentKnowledgeItem?: {
    __typename?: 'AgentKnowledge'
    documentType: string
    uuid: string
    sourceType: string
    sourceName: string
    sourceUuid: string
    content: string
  } | null
}

export type CompanyAgentQueryVariables = Exact<{ [key: string]: never }>

export type CompanyAgentQuery = {
  __typename?: 'Query'
  companyAgent?: {
    __typename?: 'CompanyAgent'
    id: string
    uuid: string
    createdAt: any
    updatedAt?: any | null
  } | null
}

export type AnalysesQueryVariables = Exact<{
  agentUuid?: InputMaybe<Scalars['ID']['input']>
}>

export type AnalysesQuery = {
  __typename?: 'Query'
  analyses?: Array<{
    __typename?: 'Analysis'
    id: string
    uuid: string
    name: string
    description: string
    synthesis: string
    status: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName: string
      label: string
      jobTitle: string
      primer: string
      backend: string
      profileImageUrl?: string | null
      shortDescription: string
      description: string
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }
  }> | null
}

export type AnalysisQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type AnalysisQuery = {
  __typename?: 'Query'
  analysis?: {
    __typename?: 'Analysis'
    id: string
    uuid: string
    name: string
    description: string
    synthesis: string
    status: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName: string
      label: string
      jobTitle: string
      primer: string
      backend: string
      profileImageUrl?: string | null
      shortDescription: string
      description: string
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }
  } | null
}

export type CreateAnalysisMutationVariables = Exact<{
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  synthesis: Scalars['String']['input']
  agentUuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
  cronSchedule?: InputMaybe<Scalars['String']['input']>
}>

export type CreateAnalysisMutation = {
  __typename?: 'Mutation'
  createAnalysis: {
    __typename?: 'AnalysisCreateResponse'
    success?: boolean | null
    errors?: Array<string> | null
    analysis?: { __typename?: 'Analysis'; uuid: string } | null
  }
}

export type UpdateAnalysisMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  synthesis: Scalars['String']['input']
  agentUuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
  cronSchedule?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateAnalysisMutation = {
  __typename?: 'Mutation'
  updateAnalysis: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type DeleteAnalysisMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteAnalysisMutation = {
  __typename?: 'Mutation'
  deleteAnalysis: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type SubmitRunAnalysisMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type SubmitRunAnalysisMutation = {
  __typename?: 'Mutation'
  submitRunAnalysis: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type LastAnalysisRunQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type LastAnalysisRunQuery = {
  __typename?: 'Query'
  lastAnalysisRun?: {
    __typename?: 'AnalysisRun'
    uuid: string
    synthesis?: string | null
    responseMessage?: string | null
    messageThreadUuid?: string | null
    webhookUrl?: string | null
    webhookResponse?: any | null
    startedAt?: any | null
    finishedAt?: any | null
    createdAt: any
    updatedAt: any
    tasks: Array<{
      __typename?: 'AnalysisTaskResult'
      name: string
      description: string
      task?: string | null
      createdAt: any
      response?: Array<
        | { __typename?: 'ImageContent'; type: string; value: string }
        | {
            __typename?: 'TextContent'
            type: string
            value: string
            files?: Array<{
              __typename?: 'AgentFile'
              value: string
              filePath?: string | null
              fileUuid?: string | null
            }> | null
          }
      > | null
    }>
    analysis?: {
      __typename?: 'Analysis'
      id: string
      uuid: string
      name: string
      description: string
      synthesis: string
      status: string
      webhookUrl?: string | null
      cronSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
      tasks: Array<{
        __typename?: 'Task'
        id: string
        uuid: string
        name: string
        description: string
        task: string
        taskType?: string | null
        status: string
        taskableType: string
        taskableUuid: string
        outputWebhookUrl?: string | null
        runSchedule?: string | null
        createdAt: any
        createdBy: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        }
      }>
      agent: {
        __typename?: 'Agent'
        id: string
        uuid: string
        firstName: string
        label: string
        jobTitle: string
        primer: string
        backend: string
        profileImageUrl?: string | null
        shortDescription: string
        description: string
        createdAt: any
        createdBy: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        }
      }
    } | null
  } | null
}

export type HistoricAnalysisRunsQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type HistoricAnalysisRunsQuery = {
  __typename?: 'Query'
  historicAnalysisRuns?: Array<{
    __typename?: 'AnalysisRun'
    uuid: string
    synthesis?: string | null
    responseMessage?: string | null
    messageThreadUuid?: string | null
    webhookUrl?: string | null
    webhookResponse?: any | null
    startedAt?: any | null
    finishedAt?: any | null
    createdAt: any
    updatedAt: any
    tasks: Array<{
      __typename?: 'AnalysisTaskResult'
      name: string
      description: string
      task?: string | null
      createdAt: any
      response?: Array<
        | { __typename?: 'ImageContent'; type: string; value: string }
        | {
            __typename?: 'TextContent'
            type: string
            value: string
            files?: Array<{
              __typename?: 'AgentFile'
              value: string
              filePath?: string | null
              fileUuid?: string | null
            }> | null
          }
      > | null
    }>
    analysis?: {
      __typename?: 'Analysis'
      id: string
      uuid: string
      name: string
      description: string
      synthesis: string
      status: string
      webhookUrl?: string | null
      cronSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
      tasks: Array<{
        __typename?: 'Task'
        id: string
        uuid: string
        name: string
        description: string
        task: string
        taskType?: string | null
        status: string
        taskableType: string
        taskableUuid: string
        outputWebhookUrl?: string | null
        runSchedule?: string | null
        createdAt: any
        createdBy: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        }
      }>
      agent: {
        __typename?: 'Agent'
        id: string
        uuid: string
        firstName: string
        label: string
        jobTitle: string
        primer: string
        backend: string
        profileImageUrl?: string | null
        shortDescription: string
        description: string
        createdAt: any
        createdBy: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        }
      }
    } | null
  }> | null
}

export type OneAnalysisRunQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type OneAnalysisRunQuery = {
  __typename?: 'Query'
  oneAnalysisRun?: {
    __typename?: 'AnalysisRun'
    uuid: string
    synthesis?: string | null
    responseMessage?: string | null
    messageThreadUuid?: string | null
    webhookUrl?: string | null
    webhookResponse?: any | null
    startedAt?: any | null
    finishedAt?: any | null
    createdAt: any
    updatedAt: any
    tasks: Array<{
      __typename?: 'AnalysisTaskResult'
      name: string
      description: string
      task?: string | null
      createdAt: any
      response?: Array<
        | { __typename?: 'ImageContent'; type: string; value: string }
        | {
            __typename?: 'TextContent'
            type: string
            value: string
            files?: Array<{
              __typename?: 'AgentFile'
              value: string
              filePath?: string | null
              fileUuid?: string | null
            }> | null
          }
      > | null
    }>
    analysis?: {
      __typename?: 'Analysis'
      id: string
      uuid: string
      name: string
      description: string
      synthesis: string
      status: string
      webhookUrl?: string | null
      cronSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
      tasks: Array<{
        __typename?: 'Task'
        id: string
        uuid: string
        name: string
        description: string
        task: string
        taskType?: string | null
        status: string
        taskableType: string
        taskableUuid: string
        outputWebhookUrl?: string | null
        runSchedule?: string | null
        createdAt: any
        createdBy: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        }
      }>
      agent: {
        __typename?: 'Agent'
        id: string
        uuid: string
        firstName: string
        label: string
        jobTitle: string
        primer: string
        backend: string
        profileImageUrl?: string | null
        shortDescription: string
        description: string
        createdAt: any
        createdBy: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        }
      }
    } | null
  } | null
}

export type UpdateDataCredentialsMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  credentials: Scalars['JSON']['input']
}>

export type UpdateDataCredentialsMutation = {
  __typename?: 'Mutation'
  updateDataCredentials: {
    __typename?: 'ResponseStatus'
    errors?: Array<string> | null
    success?: boolean | null
  }
}

export type DataCredentialsQueryVariables = Exact<{ [key: string]: never }>

export type DataCredentialsQuery = {
  __typename?: 'Query'
  dataCredentials?: Array<{
    __typename?: 'DataCredential'
    credentials: any
    credentialType: string
    uuid: string
  }> | null
}

export type CreateDataCredentialsMutationVariables = Exact<{
  credentials: Scalars['JSON']['input']
  credentialType: Scalars['String']['input']
}>

export type CreateDataCredentialsMutation = {
  __typename?: 'Mutation'
  createDataCredentials: {
    __typename?: 'ResponseStatus'
    errors?: Array<string> | null
    success?: boolean | null
  }
}

export type DeleteDataCredentialsMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteDataCredentialsMutation = {
  __typename?: 'Mutation'
  deleteDataCredentials: {
    __typename?: 'ResponseStatus'
    errors?: Array<string> | null
    success?: boolean | null
  }
}

export type CreateDataSetMutationVariables = Exact<{
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  definition: Scalars['JSON']['input']
}>

export type CreateDataSetMutation = {
  __typename?: 'Mutation'
  createDataSet: {
    __typename?: 'DataSetCreateResponse'
    success?: boolean | null
    errors?: Array<string> | null
    dataSet?: {
      __typename?: 'DataSet'
      id: number
      uuid: string
      name: string
      dataSample?: string | null
      status?: string | null
      description: string
      definition: any
      createdAt: any
      updatedAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    } | null
  }
}

export type DeleteDataSetMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteDataSetMutation = {
  __typename?: 'Mutation'
  deleteDataSet: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateDataSetMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  definition: Scalars['JSON']['input']
}>

export type UpdateDataSetMutation = {
  __typename?: 'Mutation'
  updateDataSet: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type DataSetQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DataSetQuery = {
  __typename?: 'Query'
  dataSet?: {
    __typename?: 'DataSet'
    id: number
    uuid: string
    name: string
    dataSample?: string | null
    status?: string | null
    description: string
    definition: any
    createdAt: any
    updatedAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
  } | null
}

export type AgentDataSetsQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type AgentDataSetsQuery = {
  __typename?: 'Query'
  agentDataSets?: Array<{
    __typename?: 'DataSet'
    id: number
    uuid: string
    name: string
    dataSample?: string | null
    status?: string | null
    description: string
    definition: any
    createdAt: any
    updatedAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
  }> | null
}

export type OrganizationDataSetsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationDataSetsQuery = {
  __typename?: 'Query'
  organizationDataSets?: Array<{
    __typename?: 'DataSet'
    id: number
    uuid: string
    name: string
    dataSample?: string | null
    status?: string | null
    description: string
    definition: any
    createdAt: any
    updatedAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
  }> | null
}

export type AddDataSetToAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  dataSetUuid: Scalars['String']['input']
}>

export type AddDataSetToAgentMutation = {
  __typename?: 'Mutation'
  addDataSetToAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type RemoveDataSetFromAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  dataSetUuid: Scalars['String']['input']
}>

export type RemoveDataSetFromAgentMutation = {
  __typename?: 'Mutation'
  removeDataSetFromAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UserFieldsFragment = {
  __typename?: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
}

export type AgentFieldsFragment = {
  __typename?: 'Agent'
  id: string
  uuid: string
  firstName: string
  label: string
  jobTitle: string
  primer: string
  backend: string
  profileImageUrl?: string | null
  shortDescription: string
  description: string
  createdAt: any
  createdBy: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
  }
}

export type CompanyAgentFieldsFragment = {
  __typename?: 'CompanyAgent'
  id: string
  uuid: string
  createdAt: any
  updatedAt?: any | null
}

export type TaskFieldsFragment = {
  __typename?: 'Task'
  id: string
  uuid: string
  name: string
  description: string
  task: string
  taskType?: string | null
  status: string
  taskableType: string
  taskableUuid: string
  outputWebhookUrl?: string | null
  runSchedule?: string | null
  createdAt: any
  createdBy: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
  }
}

export type AnalysisFieldsFragment = {
  __typename?: 'Analysis'
  id: string
  uuid: string
  name: string
  description: string
  synthesis: string
  status: string
  webhookUrl?: string | null
  cronSchedule?: string | null
  createdAt: any
  createdBy: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
  }
  tasks: Array<{
    __typename?: 'Task'
    id: string
    uuid: string
    name: string
    description: string
    task: string
    taskType?: string | null
    status: string
    taskableType: string
    taskableUuid: string
    outputWebhookUrl?: string | null
    runSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  }>
  agent: {
    __typename?: 'Agent'
    id: string
    uuid: string
    firstName: string
    label: string
    jobTitle: string
    primer: string
    backend: string
    profileImageUrl?: string | null
    shortDescription: string
    description: string
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  }
}

export type AnalysisRunFieldsFragment = {
  __typename?: 'AnalysisRun'
  uuid: string
  synthesis?: string | null
  responseMessage?: string | null
  messageThreadUuid?: string | null
  webhookUrl?: string | null
  webhookResponse?: any | null
  startedAt?: any | null
  finishedAt?: any | null
  createdAt: any
  updatedAt: any
  tasks: Array<{
    __typename?: 'AnalysisTaskResult'
    name: string
    description: string
    task?: string | null
    createdAt: any
    response?: Array<
      | { __typename?: 'ImageContent'; type: string; value: string }
      | {
          __typename?: 'TextContent'
          type: string
          value: string
          files?: Array<{
            __typename?: 'AgentFile'
            value: string
            filePath?: string | null
            fileUuid?: string | null
          }> | null
        }
    > | null
  }>
  analysis?: {
    __typename?: 'Analysis'
    id: string
    uuid: string
    name: string
    description: string
    synthesis: string
    status: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName: string
      label: string
      jobTitle: string
      primer: string
      backend: string
      profileImageUrl?: string | null
      shortDescription: string
      description: string
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }
  } | null
}

export type WorkflowFieldsFragment = {
  __typename?: 'Workflow'
  id: string
  uuid: string
  name: string
  status?: string | null
  description: string
  webhookUrl?: string | null
  cronSchedule?: string | null
  createdAt: any
  createdBy: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
  }
  tasks: Array<{
    __typename?: 'Task'
    id: string
    uuid: string
    name: string
    description: string
    task: string
    taskType?: string | null
    status: string
    taskableType: string
    taskableUuid: string
    outputWebhookUrl?: string | null
    runSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  }>
  agent: {
    __typename?: 'Agent'
    id: string
    uuid: string
    firstName: string
    label: string
    jobTitle: string
    primer: string
    backend: string
    profileImageUrl?: string | null
    shortDescription: string
    description: string
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  }
}

export type MessageHistoryFieldsFragment = {
  __typename?: 'MessageHistory'
  id: number
  actor: ActorEnum
  feedback?: FeedbackEnum | null
  createdAt: any
  createdAtMilli: any
  updatedAt: any
  uuid: string
  message: Array<
    | { __typename?: 'ImageContent'; type: string; value: string }
    | {
        __typename?: 'TextContent'
        type: string
        value: string
        files?: Array<{
          __typename?: 'AgentFile'
          value: string
          filePath?: string | null
          fileUuid?: string | null
        }> | null
      }
  >
}

export type DataSetFieldsFragment = {
  __typename?: 'DataSet'
  id: number
  uuid: string
  name: string
  dataSample?: string | null
  status?: string | null
  description: string
  definition: any
  createdAt: any
  updatedAt: any
  createdBy?: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
  } | null
}

export type StepHistoryFieldsFragment = {
  __typename?: 'StepHistory'
  id: number
  actor: ActorEnum
  createdAt: any
  createdAtMilli: any
  updatedAt: any
  uuid: string
  step: Array<
    | {
        __typename?: 'CodeInterpreterToolCall'
        name: string
        description: string
        type: string
        codeInterpreter: {
          __typename?: 'CodeInterpret'
          input?: string | null
          outputs?: any | null
        }
      }
    | { __typename?: 'FileSearchToolCall'; name: string; description: string; type: string }
    | {
        __typename?: 'FunctionToolCall'
        name: string
        description: string
        type: string
        function: { __typename?: 'ToolFunction'; name: string; arguments: any; output: string }
      }
  >
}

export type TaskRunFieldsFragment = {
  __typename?: 'TaskRun'
  uuid: string
  status: string
  responseMessage?: string | null
  responseStatus?: string | null
  startedAt?: any | null
  completedAt?: any | null
  createdAt: any
  updatedAt: any
  task: {
    __typename?: 'Task'
    id: string
    uuid: string
    name: string
    description: string
    task: string
    taskType?: string | null
    status: string
    taskableType: string
    taskableUuid: string
    outputWebhookUrl?: string | null
    runSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  }
}

export type SuggestionFieldsFragment = {
  __typename?: 'Suggestion'
  uuid: string
  body: string
  status: SuggestionStatusEnum
  createdAt: any
  updatedAt: any
  closedAt?: any | null
}

export type GetMessageHistoriesQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type GetMessageHistoriesQuery = {
  __typename?: 'Query'
  getMessageHistories?: Array<{
    __typename?: 'MessageHistory'
    id: number
    actor: ActorEnum
    feedback?: FeedbackEnum | null
    createdAt: any
    createdAtMilli: any
    updatedAt: any
    uuid: string
    message: Array<
      | { __typename?: 'ImageContent'; type: string; value: string }
      | {
          __typename?: 'TextContent'
          type: string
          value: string
          files?: Array<{
            __typename?: 'AgentFile'
            value: string
            filePath?: string | null
            fileUuid?: string | null
          }> | null
        }
    >
  }> | null
}

export type GetStepHistoriesQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type GetStepHistoriesQuery = {
  __typename?: 'Query'
  getStepHistories?: Array<{
    __typename?: 'StepHistory'
    id: number
    actor: ActorEnum
    createdAt: any
    createdAtMilli: any
    updatedAt: any
    uuid: string
    step: Array<
      | {
          __typename?: 'CodeInterpreterToolCall'
          name: string
          description: string
          type: string
          codeInterpreter: {
            __typename?: 'CodeInterpret'
            input?: string | null
            outputs?: any | null
          }
        }
      | { __typename?: 'FileSearchToolCall'; name: string; description: string; type: string }
      | {
          __typename?: 'FunctionToolCall'
          name: string
          description: string
          type: string
          function: { __typename?: 'ToolFunction'; name: string; arguments: any; output: string }
        }
    >
  }> | null
}

export type GetMessageThreadsQueryVariables = Exact<{
  agentUuid: Scalars['String']['input']
}>

export type GetMessageThreadsQuery = {
  __typename?: 'Query'
  getMessageThreads?: Array<{
    __typename?: 'MessageThread'
    createdAt: any
    updatedAt: any
    uuid: string
  }> | null
}

export type GetMessageThreadQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type GetMessageThreadQuery = {
  __typename?: 'Query'
  getMessageThread?: {
    __typename?: 'MessageThread'
    messages: Array<{
      __typename?: 'MessageHistory'
      id: number
      actor: ActorEnum
      feedback?: FeedbackEnum | null
      createdAt: any
      createdAtMilli: any
      updatedAt: any
      uuid: string
      message: Array<
        | { __typename?: 'ImageContent'; type: string; value: string }
        | {
            __typename?: 'TextContent'
            type: string
            value: string
            files?: Array<{
              __typename?: 'AgentFile'
              value: string
              filePath?: string | null
              fileUuid?: string | null
            }> | null
          }
      >
    }>
    steps: Array<{
      __typename?: 'StepHistory'
      id: number
      actor: ActorEnum
      createdAt: any
      createdAtMilli: any
      updatedAt: any
      uuid: string
      step: Array<
        | {
            __typename?: 'CodeInterpreterToolCall'
            name: string
            description: string
            type: string
            codeInterpreter: {
              __typename?: 'CodeInterpret'
              input?: string | null
              outputs?: any | null
            }
          }
        | { __typename?: 'FileSearchToolCall'; name: string; description: string; type: string }
        | {
            __typename?: 'FunctionToolCall'
            name: string
            description: string
            type: string
            function: { __typename?: 'ToolFunction'; name: string; arguments: any; output: string }
          }
      >
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName: string
      label: string
      jobTitle: string
      primer: string
      backend: string
      profileImageUrl?: string | null
      shortDescription: string
      description: string
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }
  } | null
}

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['Int']['input']
  name: Scalars['String']['input']
}>

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation'
  updateOrganization: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type CreditUsagesQueryVariables = Exact<{
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type CreditUsagesQuery = {
  __typename?: 'Query'
  creditUsages?: Array<{ __typename?: 'CreditUsage'; usageDate: any; quantity: number }> | null
}

export type OrganizationSuggestionsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationSuggestionsQuery = {
  __typename?: 'Query'
  organizationSuggestions?: Array<{
    __typename?: 'Suggestion'
    uuid: string
    body: string
    status: SuggestionStatusEnum
    createdAt: any
    updatedAt: any
    closedAt?: any | null
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName: string
      label: string
      jobTitle: string
      primer: string
      backend: string
      profileImageUrl?: string | null
      shortDescription: string
      description: string
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }
  }> | null
}

export type AgentSuggestionsQueryVariables = Exact<{
  agentUuid: Scalars['String']['input']
}>

export type AgentSuggestionsQuery = {
  __typename?: 'Query'
  agentSuggestions?: Array<{
    __typename?: 'Suggestion'
    uuid: string
    body: string
    status: SuggestionStatusEnum
    createdAt: any
    updatedAt: any
    closedAt?: any | null
  }> | null
}

export type ToggleSuggestionStatusMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type ToggleSuggestionStatusMutation = {
  __typename?: 'Mutation'
  toggleSuggestionStatus: {
    __typename?: 'ToggleSuggestionStatus'
    success?: boolean | null
    errors?: Array<string> | null
    status?: string | null
  }
}

export type TasksQueryVariables = Exact<{
  taskableUuid: Scalars['ID']['input']
  taskableType: Scalars['String']['input']
}>

export type TasksQuery = {
  __typename?: 'Query'
  tasks?: Array<{
    __typename?: 'Task'
    id: string
    uuid: string
    name: string
    description: string
    task: string
    taskType?: string | null
    status: string
    taskableType: string
    taskableUuid: string
    outputWebhookUrl?: string | null
    runSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  }> | null
}

export type TaskQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type TaskQuery = {
  __typename?: 'Query'
  task?: {
    __typename?: 'Task'
    id: string
    uuid: string
    name: string
    description: string
    task: string
    taskType?: string | null
    status: string
    taskableType: string
    taskableUuid: string
    outputWebhookUrl?: string | null
    runSchedule?: string | null
    createdAt: any
    taskable:
      | { __typename: 'Analysis'; uuid: string; name: string; description: string }
      | { __typename: 'Workflow'; uuid: string; name: string; description: string }
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  } | null
}

export type CreateTaskMutationVariables = Exact<{
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  task: Scalars['String']['input']
  outputWebhookUrl: Scalars['String']['input']
  runSchedule: Scalars['String']['input']
  taskableUuid: Scalars['String']['input']
  taskableType: Scalars['String']['input']
  taskType?: InputMaybe<Scalars['String']['input']>
}>

export type CreateTaskMutation = {
  __typename?: 'Mutation'
  createTask: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateTaskMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  task: Scalars['String']['input']
  outputWebhookUrl: Scalars['String']['input']
  runSchedule: Scalars['String']['input']
  taskType?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateTaskMutation = {
  __typename?: 'Mutation'
  updateTask: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type DeleteTaskMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteTaskMutation = {
  __typename?: 'Mutation'
  deleteTask: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type SubmitRunTaskMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type SubmitRunTaskMutation = {
  __typename?: 'Mutation'
  submitRunTask: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type TaskRunsQueryVariables = Exact<{
  taskUuid: Scalars['ID']['input']
}>

export type TaskRunsQuery = {
  __typename?: 'Query'
  taskRuns?: Array<{
    __typename?: 'TaskRun'
    id: string
    uuid: string
    status: string
    state: string
    createdAt: any
    startedAt?: any | null
    completedAt?: any | null
    runtimeSeconds?: number | null
    webhookResponseStatus?: string | null
    webhookStatus?: string | null
    webhookCompletedAt?: any | null
    requestMessage?: string | null
    responseMessage?: string | null
    responseStatus?: string | null
    task: { __typename?: 'Task'; task: string }
    messageHistory?: Array<{
      __typename?: 'MessageHistory'
      id: number
      actor: ActorEnum
      feedback?: FeedbackEnum | null
      createdAt: any
      createdAtMilli: any
      updatedAt: any
      uuid: string
      message: Array<
        | { __typename?: 'ImageContent'; type: string; value: string }
        | {
            __typename?: 'TextContent'
            type: string
            value: string
            files?: Array<{
              __typename?: 'AgentFile'
              value: string
              filePath?: string | null
              fileUuid?: string | null
            }> | null
          }
      >
    }> | null
    stepHistory?: Array<{
      __typename?: 'StepHistory'
      id: number
      actor: ActorEnum
      createdAt: any
      createdAtMilli: any
      updatedAt: any
      uuid: string
      step: Array<
        | {
            __typename?: 'CodeInterpreterToolCall'
            name: string
            description: string
            type: string
            codeInterpreter: {
              __typename?: 'CodeInterpret'
              input?: string | null
              outputs?: any | null
            }
          }
        | { __typename?: 'FileSearchToolCall'; name: string; description: string; type: string }
        | {
            __typename?: 'FunctionToolCall'
            name: string
            description: string
            type: string
            function: { __typename?: 'ToolFunction'; name: string; arguments: any; output: string }
          }
      >
    }> | null
    agent?: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName: string
      label: string
      jobTitle: string
      primer: string
      backend: string
      profileImageUrl?: string | null
      shortDescription: string
      description: string
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    } | null
    evaluationMessage?: {
      __typename?: 'EvaluationMessage'
      reason?: string | null
      status?: boolean | null
    } | null
  }> | null
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    privacyPolicyAccepted: boolean
    shortName: string
    longName: string
    organization: {
      __typename?: 'Organization'
      id: string
      name: string
      restrictByGroupOrRole: boolean
      emailNamespace?: string | null
      isPaid: boolean
    }
    roles: Array<{ __typename?: 'Role'; id: string; name: string }>
  } | null
}

export type TrueUserQueryVariables = Exact<{ [key: string]: never }>

export type TrueUserQuery = {
  __typename?: 'Query'
  trueUser?: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    privacyPolicyAccepted: boolean
    shortName: string
    longName: string
    organization: {
      __typename?: 'Organization'
      id: string
      name: string
      restrictByGroupOrRole: boolean
      emailNamespace?: string | null
      isPaid: boolean
    }
    roles: Array<{ __typename?: 'Role'; id: string; name: string }>
  } | null
}

export type OrganizationUsersQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
}>

export type OrganizationUsersQuery = {
  __typename?: 'Query'
  items: Array<{
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    createdAt: any
    updatedAt: any
    isInvitedPendingRegistration: boolean
    roles: Array<{ __typename?: 'Role'; id: string; name: string }>
  }>
  total: { __typename?: 'MetaData'; count: number }
}

export type SendInvitesMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type SendInvitesMutation = {
  __typename?: 'Mutation'
  sendInvites: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type ResendEmailInviteMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type ResendEmailInviteMutation = {
  __typename?: 'Mutation'
  resendEmailInvite: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateCurrentUserMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  privacyPolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>
  password?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateCurrentUserMutation = {
  __typename?: 'Mutation'
  updateCurrentUser: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type StopImpersonatingMutationVariables = Exact<{ [key: string]: never }>

export type StopImpersonatingMutation = {
  __typename?: 'Mutation'
  stopImpersonating: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AddToWaitlistMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type AddToWaitlistMutation = {
  __typename?: 'Mutation'
  addToWaitlist: {
    __typename?: 'WaitlistResponse'
    errors: Array<string>
    success: boolean
    placeInLine: number
  }
}

export type WorkflowsQueryVariables = Exact<{ [key: string]: never }>

export type WorkflowsQuery = {
  __typename?: 'Query'
  workflows?: Array<{
    __typename?: 'Workflow'
    id: string
    uuid: string
    name: string
    status?: string | null
    description: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName: string
      label: string
      jobTitle: string
      primer: string
      backend: string
      profileImageUrl?: string | null
      shortDescription: string
      description: string
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }
  }> | null
}

export type WorkflowQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type WorkflowQuery = {
  __typename?: 'Query'
  workflow?: {
    __typename?: 'Workflow'
    id: string
    uuid: string
    name: string
    status?: string | null
    description: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName: string
      label: string
      jobTitle: string
      primer: string
      backend: string
      profileImageUrl?: string | null
      shortDescription: string
      description: string
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }
  } | null
}

export type DeleteWorkflowMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteWorkflowMutation = {
  __typename?: 'Mutation'
  deleteWorkflow: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type CreateWorkflowMutationVariables = Exact<{
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  agentUuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
  cronSchedule?: InputMaybe<Scalars['String']['input']>
}>

export type CreateWorkflowMutation = {
  __typename?: 'Mutation'
  createWorkflow: {
    __typename?: 'WorkflowCreateResponse'
    success?: boolean | null
    errors?: Array<string> | null
    workflow?: { __typename?: 'Workflow'; uuid: string } | null
  }
}

export type UpdateWorkflowMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  agentUuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
  cronSchedule?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateWorkflowMutation = {
  __typename?: 'Mutation'
  updateWorkflow: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AgentWorkflowsQueryVariables = Exact<{
  agentUuid: Scalars['ID']['input']
}>

export type AgentWorkflowsQuery = {
  __typename?: 'Query'
  agentWorkflows?: Array<{
    __typename?: 'Workflow'
    id: string
    uuid: string
    name: string
    status?: string | null
    description: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName: string
      label: string
      jobTitle: string
      primer: string
      backend: string
      profileImageUrl?: string | null
      shortDescription: string
      description: string
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }
  }> | null
}

export type WorkflowTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type WorkflowTemplatesQuery = {
  __typename?: 'Query'
  workflowTemplates?: Array<{
    __typename?: 'WorkflowTemplate'
    uuid: string
    name: string
    description: string
    tasks: Array<{
      __typename?: 'TaskTemplate'
      name: string
      description: string
      task: string
      runSchedule?: string | null
      taskType: string
    }>
  }> | null
}

export type CreateWorkflowFromTemplateMutationVariables = Exact<{
  templateUuid: Scalars['String']['input']
  agentUuid: Scalars['String']['input']
}>

export type CreateWorkflowFromTemplateMutation = {
  __typename?: 'Mutation'
  createWorkflowFromTemplate: {
    __typename?: 'WorkflowCreateResponse'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type TaskRunMarkPassedMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type TaskRunMarkPassedMutation = {
  __typename?: 'Mutation'
  taskRunMarkPassed: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type SubmitRunWorkflowMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type SubmitRunWorkflowMutation = {
  __typename?: 'Mutation'
  submitRunWorkflow: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type CreateEventMutationVariables = Exact<{
  type: Scalars['String']['input']
  subjectType: EventSubjectType
  subjectId: Scalars['ID']['input']
  source?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['JSON']['input']>
  timestamp: Scalars['Float']['input']
}>

export type CreateEventMutation = {
  __typename?: 'Mutation'
  event: { __typename?: 'RrwebEvents'; data?: string | null }
}

export type CreateLearningActivityMutationVariables = Exact<{
  subjectType?: InputMaybe<LearningActivitySubjectType>
  subjectId?: InputMaybe<Scalars['ID']['input']>
  objectType: LearningActivityObjectType
  action: LearningActivityActionType
  objectId: Scalars['ID']['input']
}>

export type CreateLearningActivityMutation = {
  __typename?: 'Mutation'
  item: {
    __typename?: 'LearningActivity'
    id: string
    objectId: string
    objectType: LearningActivityObjectType
    action: LearningActivityActionType
    subjectType?: LearningActivitySubjectType | null
    subjectId: string
  }
}

export type AllRolesQueryVariables = Exact<{ [key: string]: never }>

export type AllRolesQuery = {
  __typename?: 'Query'
  roles: Array<{ __typename?: 'Role'; id: string; name: string; createdAt: any; updatedAt: any }>
}

export type SkillSetQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type SkillSetQuery = {
  __typename?: 'Query'
  skillSet: {
    __typename?: 'SkillSet'
    id: string
    name: string
    description?: string | null
    uuid: string
    canEdit?: boolean | null
    canMove?: boolean | null
    assignedUserIds: Array<string>
    sharedAccess: string
    canEditSharing?: boolean | null
    completed?: boolean | null
    isAssigned?: boolean | null
    isInMyLibrary?: boolean | null
    isInMyOrganizationLibrary?: boolean | null
    createdAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    walkthroughs?: Array<{
      __typename?: 'Walkthrough'
      id: string
      uuid: string
      name: string
      description?: string | null
      steps: Array<any>
      meta?: any | null
      canEdit?: boolean | null
      canMove?: boolean | null
      isOwner?: boolean | null
      isInMyLibrary?: boolean | null
      isInMyOrganizationLibrary?: boolean | null
      canEditSharing?: boolean | null
      canDelete?: boolean | null
      sharedAccess: string
      completed?: boolean | null
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        firstName?: string | null
        lastName?: string | null
        id: string
      } | null
      skillSet?: {
        __typename: 'SkillSet'
        id: string
        name: string
        description?: string | null
        uuid: string
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        canEditSharing?: boolean | null
      } | null
    }> | null
  }
}

export type DeleteSkillSetMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteSkillSetMutation = {
  __typename?: 'Mutation'
  deleteSkillSet: { __typename?: 'SkillSet'; id: string }
}

export type UpdateSkillSetMutationVariables = Exact<{
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
}>

export type UpdateSkillSetMutation = {
  __typename?: 'Mutation'
  updateSkillSet: {
    __typename?: 'SkillSet'
    id: string
    name: string
    description?: string | null
    uuid: string
    createdBy?: { __typename?: 'User'; id: string; firstName?: string | null } | null
    walkthroughs?: Array<{
      __typename?: 'Walkthrough'
      id: string
      uuid: string
      name: string
    }> | null
  }
}

export type CreateSkillSetMutationVariables = Exact<{
  name: Scalars['String']['input']
  description: Scalars['String']['input']
}>

export type CreateSkillSetMutation = {
  __typename?: 'Mutation'
  data: {
    __typename?: 'SkillSet'
    id: string
    name: string
    description?: string | null
    uuid: string
    createdAt: any
    updatedAt: any
    createdBy?: { __typename?: 'User'; id: string } | null
  }
}

export type ShareSkillSetMutationVariables = Exact<{
  id: Scalars['ID']['input']
  shareType: ShareTypeEnum
}>

export type ShareSkillSetMutation = {
  __typename?: 'Mutation'
  shareSkillSet: {
    __typename?: 'SkillSet'
    id: string
    name: string
    sharedAccess: string
    isInMyLibrary?: boolean | null
    isInMyOrganizationLibrary?: boolean | null
    uuid: string
  }
}

export type MoveSkillSetMutationVariables = Exact<{
  id: Scalars['ID']['input']
  destinationType: DestinationType
  destinationId?: InputMaybe<Scalars['ID']['input']>
}>

export type MoveSkillSetMutation = {
  __typename?: 'Mutation'
  skillSet: {
    __typename?: 'SkillSet'
    id: string
    uuid: string
    name: string
    isAssigned?: boolean | null
    isInMyLibrary?: boolean | null
    isInMyOrganizationLibrary?: boolean | null
  }
}

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  organization: Scalars['ID']['input']
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>
  password?: InputMaybe<Scalars['String']['input']>
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  data: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    shortName: string
    longName: string
  }
}

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>
  password?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  data: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    shortName: string
    longName: string
  }
}

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteUserMutation = {
  __typename?: 'Mutation'
  data: { __typename?: 'User'; id: string }
}

export type CreateUserActivityMutationVariables = Exact<{
  action: Scalars['String']['input']
  url: Scalars['String']['input']
  metadata?: InputMaybe<Scalars['JSON']['input']>
  objectType?: InputMaybe<UserActivityObjectType>
  objectId?: InputMaybe<Scalars['ID']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}>

export type CreateUserActivityMutation = {
  __typename?: 'Mutation'
  createUserActivity: { __typename?: 'UserActivity'; id: string }
}

export type UploadAudioMutationVariables = Exact<{
  file: Scalars['Upload']['input']
  walkthroughId: Scalars['ID']['input']
}>

export type UploadAudioMutation = {
  __typename?: 'Mutation'
  uploadAudio: { __typename?: 'Audio'; id: string; uuid: string; audioUrl?: string | null }
}

export type GetCreatedWalkthroughsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
  contentType?: InputMaybe<ContentType>
}>

export type GetCreatedWalkthroughsQuery = {
  __typename?: 'Query'
  items: Array<
    | {
        __typename: 'SkillSet'
        id: string
        name: string
        uuid: string
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        isAssigned?: boolean | null
        canEditSharing?: boolean | null
        walkthroughs?: Array<{
          __typename?: 'Walkthrough'
          id: string
          name: string
          uuid: string
          steps: Array<any>
          completed?: boolean | null
          meta?: any | null
        }> | null
      }
    | {
        __typename: 'Walkthrough'
        id: string
        uuid: string
        name: string
        description?: string | null
        steps: Array<any>
        meta?: any | null
        audioEnabled: boolean
        hasAudio: boolean
        canEdit?: boolean | null
        canMove?: boolean | null
        isOwner?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        canEditSharing?: boolean | null
        canDelete?: boolean | null
        sharedAccess: string
        completed?: boolean | null
        createdAt: any
        viewCount: number
        interactionCount: number
        createdBy?: {
          __typename?: 'User'
          firstName?: string | null
          lastName?: string | null
          id: string
        } | null
        skillSet?: {
          __typename: 'SkillSet'
          id: string
          name: string
          uuid: string
          sharedAccess: string
        } | null
      }
  >
  total: { __typename?: 'MetaData'; count: number }
}

export type WalkthroughByUuidQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type WalkthroughByUuidQuery = {
  __typename?: 'Query'
  walkthrough: {
    __typename?: 'Walkthrough'
    id: string
    uuid: string
    name: string
    description?: string | null
    meta?: any | null
    steps: Array<any>
    audioEnabled: boolean
    hasAudio: boolean
    isOwner?: boolean | null
    isInMyLibrary?: boolean | null
    isInMyOrganizationLibrary?: boolean | null
    canEdit?: boolean | null
    canMove?: boolean | null
    canEditSharing?: boolean | null
    createdAt: any
    canDelete?: boolean | null
    sharedAccess: string
    completed?: boolean | null
    rrwebEventsUrl?: string | null
    audioUrl?: string | null
    liveLearning?: boolean | null
    labs?: boolean | null
    embedEnabled: boolean
    viewCount: number
    interactionCount: number
    synthesis?: string | null
    synthesisStatus?: string | null
    summary?: string | null
    lastSynthesisAt?: any | null
    updatedAt: any
    createdBy?: {
      __typename?: 'User'
      firstName?: string | null
      lastName?: string | null
      id: string
    } | null
    skillSet?: {
      __typename?: 'SkillSet'
      id: string
      name: string
      uuid: string
      isInMyLibrary?: boolean | null
      isInMyOrganizationLibrary?: boolean | null
      sharedAccess: string
      canEditSharing?: boolean | null
    } | null
  }
}

export type GetCompletedWalkthroughsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
  contentType?: InputMaybe<ContentType>
}>

export type GetCompletedWalkthroughsQuery = {
  __typename?: 'Query'
  items: Array<
    | {
        __typename: 'SkillSet'
        id: string
        name: string
        uuid: string
        assignedUserIds: Array<string>
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        isAssigned?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        createdAt: any
        canEditSharing?: boolean | null
        walkthroughs?: Array<{
          __typename?: 'Walkthrough'
          id: string
          name: string
          uuid: string
          steps: Array<any>
          completed?: boolean | null
          meta?: any | null
        }> | null
      }
    | {
        __typename: 'Walkthrough'
        id: string
        description?: string | null
        name: string
        steps: Array<any>
        uuid: string
        meta?: any | null
        isOwner?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        canEditSharing?: boolean | null
        canDelete?: boolean | null
        sharedAccess: string
        createdAt: any
        updatedAt: any
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        viewCount: number
        interactionCount: number
        createdBy?: {
          __typename?: 'User'
          firstName?: string | null
          lastName?: string | null
          id: string
        } | null
        skillSet?: {
          __typename: 'SkillSet'
          id: string
          name: string
          uuid: string
          sharedAccess: string
        } | null
      }
  >
  total: { __typename?: 'MetaData'; count: number }
}

export type DeleteWalkthroughMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId?: InputMaybe<Scalars['ID']['input']>
}>

export type DeleteWalkthroughMutation = {
  __typename?: 'Mutation'
  deleteWalkthrough: { __typename?: 'Walkthrough'; id: string }
}

export type MoveWalkthroughMutationVariables = Exact<{
  id: Scalars['ID']['input']
  destinationType: DestinationType
  destinationId?: InputMaybe<Scalars['ID']['input']>
}>

export type MoveWalkthroughMutation = {
  __typename?: 'Mutation'
  walkthrough: {
    __typename?: 'Walkthrough'
    id: string
    uuid: string
    name: string
    isInMyLibrary?: boolean | null
    isInMyOrganizationLibrary?: boolean | null
    skillSet?: { __typename?: 'SkillSet'; uuid: string } | null
  }
}

export type UpdateWalkthroughMutationVariables = Exact<{
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  steps: Array<Scalars['JSON']['input']> | Scalars['JSON']['input']
  liveLearning?: InputMaybe<Scalars['Boolean']['input']>
  labs?: InputMaybe<Scalars['Boolean']['input']>
  audioEnabled?: InputMaybe<Scalars['Boolean']['input']>
  synthesis?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateWalkthroughMutation = {
  __typename?: 'Mutation'
  updateWalkthrough: {
    __typename?: 'Walkthrough'
    id: string
    uuid: string
    description?: string | null
    name: string
    steps: Array<any>
    audioEnabled: boolean
    audioUrl?: string | null
    synthesis?: string | null
  }
}

export type ShareWalkthroughMutationVariables = Exact<{
  id: Scalars['ID']['input']
  shareType: ShareTypeEnum
}>

export type ShareWalkthroughMutation = {
  __typename?: 'Mutation'
  shareWalkthrough: {
    __typename?: 'Walkthrough'
    id: string
    name: string
    sharedAccess: string
    isInMyOrganizationLibrary?: boolean | null
    isInMyLibrary?: boolean | null
  }
}

export type ShareEmbedWalkthroughMutationVariables = Exact<{
  id: Scalars['ID']['input']
  enabled: Scalars['Boolean']['input']
}>

export type ShareEmbedWalkthroughMutation = {
  __typename?: 'Mutation'
  shareEmbedWalkthrough: { __typename?: 'Walkthrough'; id: string }
}

export type GetRecentlyViewedWalkthroughsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
  contentType?: InputMaybe<ContentType>
}>

export type GetRecentlyViewedWalkthroughsQuery = {
  __typename?: 'Query'
  items: Array<
    | {
        __typename: 'SkillSet'
        id: string
        name: string
        uuid: string
        assignedUserIds: Array<string>
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        isAssigned?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        canEditSharing?: boolean | null
        walkthroughs?: Array<{
          __typename?: 'Walkthrough'
          id: string
          name: string
          steps: Array<any>
          uuid: string
          completed?: boolean | null
          meta?: any | null
        }> | null
      }
    | {
        __typename: 'Walkthrough'
        id: string
        description?: string | null
        name: string
        steps: Array<any>
        uuid: string
        sharedAccess: string
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        createdAt: any
        updatedAt: any
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        canEditSharing?: boolean | null
        meta?: any | null
        viewCount: number
        interactionCount: number
        skillSet?: {
          __typename: 'SkillSet'
          id: string
          name: string
          uuid: string
          sharedAccess: string
        } | null
      }
  >
  total: { __typename?: 'MetaData'; count: number }
}

export type GetWalkthroughsSharedWithMeQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
  contentType?: InputMaybe<ContentType>
}>

export type GetWalkthroughsSharedWithMeQuery = {
  __typename?: 'Query'
  items: Array<
    | {
        __typename: 'SkillSet'
        id: string
        name: string
        uuid: string
        assignedUserIds: Array<string>
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        isAssigned?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        canEditSharing?: boolean | null
        walkthroughs?: Array<{
          __typename?: 'Walkthrough'
          id: string
          name: string
          uuid: string
          steps: Array<any>
          meta?: any | null
          completed?: boolean | null
        }> | null
      }
    | {
        __typename: 'Walkthrough'
        id: string
        description?: string | null
        name: string
        steps: Array<any>
        uuid: string
        meta?: any | null
        sharedAccess: string
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        createdAt: any
        updatedAt: any
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        canEditSharing?: boolean | null
        viewCount: number
        interactionCount: number
        createdBy?: {
          __typename?: 'User'
          firstName?: string | null
          lastName?: string | null
          id: string
        } | null
        skillSet?: {
          __typename: 'SkillSet'
          id: string
          name: string
          uuid: string
          sharedAccess: string
        } | null
      }
  >
  total: { __typename?: 'MetaData'; count: number }
}

export type GetOrganizationWalkthroughsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
  contentType?: InputMaybe<ContentType>
}>

export type GetOrganizationWalkthroughsQuery = {
  __typename?: 'Query'
  items: Array<
    | {
        __typename: 'SkillSet'
        id: string
        name: string
        uuid: string
        assignedUserIds: Array<string>
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        isAssigned?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        canEditSharing?: boolean | null
        walkthroughs?: Array<{
          __typename?: 'Walkthrough'
          id: string
          name: string
          uuid: string
          steps: Array<any>
          completed?: boolean | null
          meta?: any | null
        }> | null
      }
    | {
        __typename: 'Walkthrough'
        id: string
        description?: string | null
        name: string
        steps: Array<any>
        uuid: string
        sharedAccess: string
        createdAt: any
        updatedAt: any
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        canEditSharing?: boolean | null
        meta?: any | null
        viewCount: number
        interactionCount: number
        skillSet?: {
          __typename: 'SkillSet'
          id: string
          name: string
          uuid: string
          sharedAccess: string
        } | null
      }
  >
  total: { __typename?: 'MetaData'; count: number }
}

export type GetGalleryContentsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
  contentType?: InputMaybe<ContentType>
}>

export type GetGalleryContentsQuery = {
  __typename?: 'Query'
  items: Array<
    | {
        __typename: 'SkillSet'
        id: string
        name: string
        uuid: string
        assignedUserIds: Array<string>
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        isAssigned?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        canEditSharing?: boolean | null
        walkthroughs?: Array<{
          __typename?: 'Walkthrough'
          id: string
          name: string
          steps: Array<any>
          uuid: string
          meta?: any | null
          completed?: boolean | null
        }> | null
      }
    | {
        __typename: 'Walkthrough'
        id: string
        description?: string | null
        name: string
        steps: Array<any>
        uuid: string
        sharedAccess: string
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        createdAt: any
        updatedAt: any
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        canEditSharing?: boolean | null
        viewCount: number
        interactionCount: number
        meta?: any | null
        skillSet?: {
          __typename: 'SkillSet'
          id: string
          name: string
          uuid: string
          sharedAccess: string
        } | null
      }
  >
  total: { __typename?: 'MetaData'; count: number }
}
