import { useQuery } from '@apollo/client'
import React from 'react'
import { BaseMessageThread } from './SidebarChat/MessageThread'
import { GET_MESSAGE_THREAD } from '../graphql/queries/message_thread'
import { GetMessageThreadQuery, GetMessageThreadQueryVariables } from '../../graphql'

interface ViewThreadProps {
  threadUuid: string
}

const ViewThread = ({ threadUuid }: ViewThreadProps) => {
  if (!threadUuid) {
    return <></>
  }

  // get the messages and steps for the thread
  const { data, loading } = useQuery<GetMessageThreadQuery, GetMessageThreadQueryVariables>(
    GET_MESSAGE_THREAD,
    {
      variables: {
        uuid: threadUuid,
      },
    }
  )

  if (loading) {
    return <></>
  }
  const messageThread = data.getMessageThread

  return (
    <BaseMessageThread
      messages={messageThread.messages}
      steps={messageThread.steps}
      agent={messageThread.agent}
      showName={true}
      showVoting={false}
    />
  )
}

export default ViewThread
