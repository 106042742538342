import React, { useCallback } from 'react'
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { FeedItemTypeEnum } from '../../../../components/graphql'
import { startCase } from 'lodash'

interface FeedFilterProps {
  onFilterChange: (activeSelections: string[]) => void
}
const FeedFilter = ({ onFilterChange }: FeedFilterProps) => {
  const filters = Object.entries(FeedItemTypeEnum)
    .map(([key, value]) => {
      return { id: value, label: startCase(key) }
    })
    .sort((a, b) => a.label.localeCompare(b.label)) // Sorting by label alphabetically

  const [activeSelections, setactiveSelections] = React.useState<string[]>(filters.map((f) => f.id))

  const handleFilterChange = useCallback(
    (e: any) => {
      const { value, checked } = e.target
      let newSelections = []
      if (checked) {
        newSelections = [...activeSelections, value]
      } else {
        newSelections = activeSelections.filter((oneActiveId) => oneActiveId !== value)
      }

      setactiveSelections(newSelections)
      onFilterChange(newSelections)
    },
    [activeSelections]
  )

  const handleSelectAll = useCallback(() => {
    const newSelections = filters.map((f) => f.id)
    setactiveSelections(newSelections)
    onFilterChange(newSelections)
  }, [activeSelections])

  const handleSelectOnly = useCallback(
    (filterId: string) => {
      const newSelections = [filterId]
      setactiveSelections(newSelections)
      onFilterChange(newSelections)
    },
    [activeSelections]
  )

  return (
    <div className="">
      <section aria-labelledby="filter-heading">
        <div className="border-b border-gray-200 bg-white py-4 rounded-t-lg">
          <div className="mx-auto flex max-w-7xl items-center justify-end px-4 sm:px-6 lg:px-8">
            <div className="hidden sm:block">
              <div className="flow-root">
                <PopoverGroup className="-mx-4 flex items-center divide-x divide-gray-200">
                  <Popover className="relative inline-block px-4 text-left">
                    <PopoverButton className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      <span>Type</span>
                      {activeSelections.length > 0 ? (
                        <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                          {activeSelections.length}
                        </span>
                      ) : null}
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      />
                    </PopoverButton>

                    <PopoverPanel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none">
                      <div className="flex justify-between items-center">
                        <button
                          type="button"
                          className="text-xs text-indigo-600 hover:underline"
                          onClick={handleSelectAll}
                        >
                          Select All
                        </button>
                      </div>
                      <form className="space-y-4 mt-2">
                        {filters.map((option, optionIdx) => (
                          <div key={option.id} className="flex items-center justify-between">
                            <div className="flex items-center">
                              <input
                                value={option.id}
                                onChange={handleFilterChange}
                                checked={activeSelections.includes(option.id)}
                                id={`filter-${option.id}-${optionIdx}`}
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-${option.id}-${optionIdx}`}
                                className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                              >
                                {option.label}
                              </label>
                            </div>
                            <button
                              type="button"
                              className="text-xs text-indigo-600 hover:underline"
                              onClick={() => handleSelectOnly(option.id)}
                            >
                              Only
                            </button>
                          </div>
                        ))}
                      </form>
                    </PopoverPanel>
                  </Popover>
                </PopoverGroup>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default FeedFilter
