import React from 'react'

import { Cloudinary } from '@cloudinary/url-gen'
import { thumbnail } from '@cloudinary/url-gen/actions/resize'
import { AgentFieldsFragment } from '../../graphql'
import clsx from 'clsx'

interface AgentImageProps {
  agent: AgentFieldsFragment
  className?: string
}

const AgentImage = ({ agent, className }: AgentImageProps) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'data-driven',
    },
    url: {
      secure: true,
    },
  })

  let profileImageURL = ''
  if (agent?.profileImageUrl) {
    const publicId = agent.profileImageUrl.split('/').slice(-2).join('/')
    const profileImage = cld
      .image(publicId)
      .resize(thumbnail().width(200).height(200).gravity('face'))
    profileImageURL = profileImage.toURL()
  }

  return (
    <>
      {profileImageURL ? (
        <img src={profileImageURL} className={clsx(className)} />
      ) : (
        <div className={clsx(className, 'bg-gray-400')}></div>
      )}
    </>
  )
}

export default AgentImage
