import React, { useEffect, useRef, useState } from 'react'
import { useEventTracking } from '../hooks/useEventTracking'
import Walkthrough from '../../App/models/Walkthrough'
import { useSelector } from 'react-redux'
import { RootState } from '../../AI/components/Player/store'

const roundToNearest10 = (number: number) => Math.floor(number / 10) * 10

interface Props {
  walkthrough: Walkthrough
  eventPrefix: string
}

const PlayerEventTracking: React.FC<Props> = ({ walkthrough, eventPrefix }) => {
  const {
    duration,
    position,
    playerControls: { isPlaying, isVideoAtEnd, isInteractiveMode, isInteractiveStep },
  } = useSelector((state: RootState) => state.player)
  const isFirstRender = useRef(true)
  const variables = { subjectId: walkthrough.id, subjectType: 'walkthrough' }

  const [watchedPercentage, setWatchedPercentage] = useState(0)
  const logEvent = useEventTracking()

  useEffect(() => {
    const proportion = position / duration
    setWatchedPercentage(roundToNearest10(proportion * 100))
  }, [duration, position])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    const toggleStatus = isInteractiveMode ? 'on' : 'off'
    logEvent({ type: `${eventPrefix}:toggle_interactive_mode_${toggleStatus}`, ...variables })
  }, [isInteractiveMode])

  useEffect(() => {
    if (isInteractiveStep) {
      logEvent({ type: `${eventPrefix}:start_interactive_step`, ...variables })
    }
  }, [isInteractiveStep])

  useEffect(() => {
    if (watchedPercentage > 0) {
      logEvent({ type: `${eventPrefix}:video_watch_${watchedPercentage}`, ...variables })
    }
  }, [watchedPercentage])

  useEffect(() => {
    if (!isVideoAtEnd) {
      if (isPlaying) {
        logEvent({ type: `${eventPrefix}:video_play`, ...variables })
      } else if (position > 0) {
        logEvent({ type: `${eventPrefix}:video_pause`, ...variables })
      }
    }
  }, [isPlaying, isVideoAtEnd])

  return <></>
}

export default PlayerEventTracking
