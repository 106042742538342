import { gql } from '@apollo/client'

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
  }
`

export const AGENT_FIELDS = gql`
  fragment AgentFields on Agent {
    id
    uuid
    firstName
    label
    jobTitle
    primer
    backend
    profileImageUrl
    shortDescription
    description
    createdAt
    createdBy {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`

export const COMPANY_AGENT_FIELDS = gql`
  fragment CompanyAgentFields on CompanyAgent {
    id
    uuid
    createdAt
    updatedAt
  }
`

export const TASK_FIELDS = gql`
  fragment TaskFields on Task {
    id
    uuid
    name
    description
    task
    taskType
    status
    taskableType
    taskableUuid
    outputWebhookUrl
    runSchedule
    createdAt
    createdBy {
      ...UserFields
    }
  }

  ${USER_FIELDS}
`

export const ANALYSIS_FIELDS = gql`
  fragment AnalysisFields on Analysis {
    id
    uuid
    name
    description
    synthesis
    status
    webhookUrl
    cronSchedule
    createdAt
    createdBy {
      ...UserFields
    }
    tasks {
      ...TaskFields
    }
    agent {
      ...AgentFields
    }
  }

  ${TASK_FIELDS}
  ${USER_FIELDS}
  ${AGENT_FIELDS}
`

export const ANALYSIS_RUN_FIELDS = gql`
  fragment AnalysisRunFields on AnalysisRun {
    uuid
    synthesis
    responseMessage
    tasks {
      name
      description
      task
      response {
        ... on ImageContent {
          type
          value
        }
        ... on TextContent {
          type
          value
          files {
            value
            filePath
            fileUuid
          }
        }
      }
      createdAt
    }
    analysis {
      ...AnalysisFields
    }
    messageThreadUuid
    webhookUrl
    webhookResponse
    startedAt
    finishedAt
    createdAt
    updatedAt
  }

  ${ANALYSIS_FIELDS}
`

export const WORKFLOW_FIELDS = gql`
  fragment WorkflowFields on Workflow {
    id
    uuid
    name
    status
    description
    webhookUrl
    cronSchedule
    createdAt
    createdBy {
      ...UserFields
    }
    tasks {
      ...TaskFields
    }
    agent {
      ...AgentFields
    }
  }

  ${TASK_FIELDS}
  ${USER_FIELDS}
  ${AGENT_FIELDS}
`

export const MESSAGE_HISTORY_FIELDS = gql`
  fragment MessageHistoryFields on MessageHistory {
    id
    actor
    feedback
    message {
      ... on ImageContent {
        type
        value
      }
      ... on TextContent {
        type
        value
        files {
          value
          filePath
          fileUuid
        }
      }
    }
    createdAt
    createdAtMilli
    updatedAt
    uuid
  }
`

export const DATA_SET_FIELDS = gql`
  fragment DataSetFields on DataSet {
    id
    uuid
    name
    createdBy {
      ...UserFields
    }
    dataSample
    status
    description
    definition
    createdAt
    updatedAt
  }
  ${USER_FIELDS}
`
export const STEP_HISTORY_FIELDS = gql`
  fragment StepHistoryFields on StepHistory {
    id
    actor
    step {
      ... on FunctionToolCall {
        name
        description
        type
        function {
          name
          arguments
          output
        }
      }
      ... on CodeInterpreterToolCall {
        name
        description
        type
        codeInterpreter {
          input
          outputs
        }
      }
      ... on FileSearchToolCall {
        name
        description
        type
      }
    }
    createdAt
    createdAtMilli
    updatedAt
    uuid
  }
`

export const TASK_RUN_FIELDS = gql`
  fragment TaskRunFields on TaskRun {
    uuid
    status
    task {
      ...TaskFields
    }
    responseMessage
    responseStatus
    startedAt
    completedAt
    createdAt
    updatedAt
  }
  ${TASK_FIELDS}
`

export const SUGGESTION_FIELDS = gql`
  fragment SuggestionFields on Suggestion {
    uuid
    body
    status
    createdAt
    updatedAt
    closedAt
  }
`
