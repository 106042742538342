import React, { useEffect, useState } from 'react'
import {
  DELETE_AGENT_MUTATION,
  GET_AGENT_QUERY,
  MUTATION_GENERATE_PROFILE_IMAGE,
} from '../../../graphql/queries/agent'
import Spin from '../../../../common/ui/Spin'
import { useMutation, useQuery } from '@apollo/client'
import { AgentQuery, AgentQueryVariables } from '../../../../graphql'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import HeaderActionButton from '../../../components/HeaderActionButton'
import DeleteModal from '../../../components/DeleteModal'

import { clsx } from 'clsx'
import DetailsForm from './DetailsForm'
import Prompts from './Prompts'
import Data from './Data'
import WorkflowList from './WorkflowList'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import BuildStatus from './BuildStatus'
import queryString from 'query-string'
import AgentImage from '../../../components/AgentImage'
import AgentHeader from '../OneAgent/AgentHeader'
import PageHeader from '../../../components/PageHeader'

type EditAgentProps = {
  uuid: string
}

const EditAgent = ({ uuid }: EditAgentProps) => {
  document.title = 'Edit Agent - FlowMo'

  const history = useHistory()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('details')
  const { user } = useSelector((state: RootState) => state.app)
  const { data, loading, refetch } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  const getTabFromUrl = (): string => {
    const values = queryString.parse(location.search)
    const tab = values.tab
    // Check if tab is an array and return the first element if so
    if (Array.isArray(tab)) {
      return tab[0]
    }
    // Return tab if it's a string, or default to 'details'
    return tab || 'details'
  }

  // Update active tab when URL changes
  useEffect(() => {
    setActiveTab(getTabFromUrl())
  }, [location])

  // Update active tab when URL changes
  useEffect(() => {
    setActiveTab(getTabFromUrl())
  }, [location])

  const [updateProfileImage] = useMutation(MUTATION_GENERATE_PROFILE_IMAGE)
  const [generatingProfileImage, setGeneratingProfileImage] = useState(false)

  const [deleteAgent] = useMutation(DELETE_AGENT_MUTATION)
  const [open, setOpen] = useState(false)

  const agent = data?.agent
  if (loading) {
    return <Spin />
  }

  if (!agent) {
    window.toastr.error('Agent not found')
    history.push('/agents')
    return <></>
  }

  if (user.isOrganizationAdmin == false && user.id.toString() != agent.createdBy.id) {
    window.toastr.error('You are not authorized to edit this agent')
    history.push('/agents')
  }

  // Update URL when tab changes
  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId)
    const newQuery = queryString.stringify({ ...queryString.parse(location.search), tab: tabId })
    history.push(`${location.pathname}?${newQuery}`)
  }

  const onUpdate = () => {
    refetch()
  }

  const handleRefreshProfileImage = async () => {
    setGeneratingProfileImage(true)
    await updateProfileImage({
      variables: { uuid: agent.uuid },
    })
    setGeneratingProfileImage(false)
    window.toastr.success('Profile image updated successfully')
    refetch()
  }

  const handleDelete = async () => {
    await deleteAgent({
      variables: { uuid: agent.uuid },
    })
    window.toastr.success('Agent deleted successfully')
    history.push('/agents')
  }

  const tabs = [
    { name: 'Details', id: 'details' },
    { name: 'Things I know', id: 'data' },
    { name: 'Stuff I can do', id: 'workflows' },
    { name: 'What to ask', id: 'prompts' },
  ]

  return (
    <React.Fragment>
      <AgentHeader agent={agent} />

      <div className="px-5">
        <PageHeader title="Edit Agent">
          <div className="my-5 flex flex-row space-x-2 justify-end">
            <BuildStatus uuid={agent.uuid} buildStatus={agent.buildStatus} onUpdate={onUpdate} />
            <HeaderActionButton onClick={() => setOpen(true)} label="Delete" />
          </div>
        </PageHeader>
      </div>

      <DeleteModal
        open={open}
        setOpen={setOpen}
        onDelete={handleDelete}
        title="Delete Agent"
        buttonLabel="Delete"
        description="Are you sure you want to delete the agent? All of your data will be permanently removed from our servers forever. This action cannot be undone."
      />

      <div className="flex flex-col mt-2">
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-flowmo-blue-500 focus:ring-flowmo-blue-500"
              defaultValue={tabs.find((tab) => tab.id == activeTab).name}
            >
              {tabs.map((tab) => (
                <option key={tab.name} onClick={() => handleTabChange(tab.id)}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block pt-2">
            <nav
              className="isolate flex divide-x divide-gray-200 rounded-lg shadow max-w-7xl m-auto"
              aria-label="Tabs"
            >
              {tabs.map((tab, tabIdx) => (
                <div
                  key={tab.name}
                  onClick={() => {
                    handleTabChange(tab.id)
                  }}
                  className={clsx(
                    tab.id == activeTab
                      ? 'text-gray-900'
                      : 'text-gray-500 hover:text-gray-700 cursor-pointer  hover:bg-gray-200/50',
                    tabIdx === 0 ? 'rounded-l-lg' : '',
                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                    'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium focus:z-10'
                  )}
                  aria-current={tab.id == activeTab ? 'page' : undefined}
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden="true"
                    className={clsx(
                      tab.id == activeTab ? 'bg-flowmo-blue-500' : 'bg-transparent',
                      'absolute inset-x-0 bottom-0 h-0.5'
                    )}
                  />
                </div>
              ))}
            </nav>
          </div>
        </div>

        <div className="flex flex-col space-y-4 bg-white p-4 mt-4 rounded-xl">
          {activeTab == 'details' && (
            <div className="flex flex-col space-y-4">
              <div className="max-w-xl">
                <div className="relative flex w-24 flex-col mb-5">
                  <AgentImage agent={agent} className="w-24 h-24 rounded-xl mb-5" />
                  {generatingProfileImage ? (
                    <Spin />
                  ) : (
                    <div
                      onClick={handleRefreshProfileImage}
                      className="flex flex-row items-center space-x-2 text-gray-800 hover:text-flowmo-blue-800 cursor-pointer"
                    >
                      <ArrowPathIcon className="h-5 w-5" />
                      <div className="text-base ">Refresh</div>
                    </div>
                  )}
                </div>

                <DetailsForm agent={agent} onUpdate={onUpdate} />
              </div>
            </div>
          )}
          {activeTab == 'data' && <Data agent={agent} onUpdate={onUpdate} />}
          {activeTab == 'workflows' && <WorkflowList agentUuid={agent.uuid} onUpdate={onUpdate} />}
          {activeTab == 'prompts' && <Prompts agent={agent} onUpdate={onUpdate} />}
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditAgent
