import React from 'react'
import { useMutation } from '@apollo/client'
import {
  AgentFieldsFragment,
  CompanyAgentFieldsFragment,
  MessageFeedbackMutation,
  MessageFeedbackMutationVariables,
  MessageHistoryFieldsFragment,
} from 'app/javascript/components/graphql'
import { MESSAGE_FEEDBACK } from '../../graphql/queries/agent'
import AgentImage from '../AgentImage'
import MessageBody from './MessageBody'
import MessageBase from './MessageBase'

interface MessageProps {
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
  message: MessageHistoryFieldsFragment
  showVoting?: boolean
  showName?: boolean
}

const Message: React.FC<MessageProps> = ({ agent, message, showVoting, showName }) => {
  const [isThumbsUp, setIsThumbsUp] = React.useState<boolean | null>(null)
  const [MessageFeedback] = useMutation<MessageFeedbackMutation, MessageFeedbackMutationVariables>(
    MESSAGE_FEEDBACK
  )

  const handleThumbsUp = () => {
    setIsThumbsUp(true)
    MessageFeedback({
      variables: {
        agentUuid: agent.uuid,
        messageUuid: message.uuid,
        feedback: 'thumbs_up',
      },
    })
  }

  const handleThumbsDown = () => {
    setIsThumbsUp(false)
    MessageFeedback({
      variables: {
        agentUuid: agent.uuid,
        messageUuid: message.uuid,
        feedback: 'thumbs_down',
      },
    })
  }

  if (!agent) {
    return <></>
  }

  let agentImage = null
  let agentFirstName = ''
  if (agent.__typename !== 'CompanyAgent') {
    agentImage = <AgentImage agent={agent} className="size-6 rounded-full" />
    agentFirstName = agent.firstName
  }

  return (
    <MessageBase
      actor={message.actor}
      agentFirstName={agentFirstName}
      agentImage={agentImage}
      createdAt={message.createdAt}
      showVoting={showVoting}
      showName={showName}
      onThumbsUp={handleThumbsUp}
      onThumbsDown={handleThumbsDown}
      isThumbsUp={isThumbsUp}
    >
      <MessageBody messages={message.message} />
    </MessageBase>
  )
}

export default Message
